import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter } from "../../MasterComponents/CommonFunctionalities"; 
import { showAlertMessage, checkIfArrayIsEmpty } from '../../MasterComponents/CommonFunctionalities';
import Settings from "../../CommonAPICalls/Settings";
 
const SystemTopicalPastPapersListings = () => {
  const [topicalPastPapersData, setTopicalPastPapersData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => { getAllSystemTopicalPastPapers(); }, []);

  const getAllSystemTopicalPastPapers = async () => { 
    try {
      const apiResponse = await apiCalls.getSystemTopicalPastPapers();
      if (apiResponse) {
        setTopicalPastPapersData(apiResponse?.data);
        setTotalRecords(apiResponse?.data?.length);
        setLoading(false);
      }
    } catch (err) {
      setTopicalPastPapersData([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onTopicalPastPaperClicked = (row, colKey) => {
    if(colKey === 'topical_paper_year'){
      navigate(`/portal/system-past-papers?paperid=${row?.past_paper_id}`);
    }  
  };

  const onDeleteSystemTopicalPastPapers = async (rowItems) => {
    if(checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoading(true);

    for (const item of rowItems) {   
      try {
        const apiResponse = await apiCalls.removeSystemTopicalPastPaper(item?.past_paper_id);
        if(apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getAllSystemTopicalPastPapers();
  };

  const gridColumnHeaders = [
    { field: 'topical_paper_year', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Topical Paper Year' },
    { field: 'topical_paper_title', columnName: 'Topical Paper Title' },
    { field: 'past_paper_type_id', columnName: 'Past Paper Type', options: Settings.enumTypes, inputType: "dropdown" },
    { field: 'topical_paper_description', columnName: 'Topical Paper Description' },
    { field: 'topical_paper_price', columnName: 'Topical Paper Price' },
    { field: 'topical_paper_drive_link', columnName: 'Topical Paper Drive Link' } 
  ];

  return (
    <div className='listings_view_wrapper'> 
      <h1 className="h1_main">All Topical Past Papers</h1>

      <CustomDataGrid 
        columns={gridColumnHeaders} 
        data={topicalPastPapersData} 
        pageSize={pageSize} 
        loadingState={loading}
        pagination={false}
        onAddClick={() => navigate('/portal/system-past-papers')}
        onCellClicked={onTopicalPastPaperClicked}
        onSelectedRowsDelete={onDeleteSystemTopicalPastPapers}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
      />
    </div>
  )
}

export default SystemTopicalPastPapersListings

import React,{useState,useEffect} from "react";
import "./PastPaperCard.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import Settings from "../../CommonAPICalls/Settings";
import Loader from "../../UI Helpers/Simple Loading/Loader";

function PastPaperCard() {
    const [currentPaperType , setCurrentPaperType] = useState(Settings.enumTypes[0]?.value)
    const [papers,setPapers] = useState([])
    const [loading, setLoading] = useState(false)
    const [error,setError] = useState(false)

    const apiCall = new CommonCalls();
    const getPapersByTypes = async()=>{
            setLoading(true)
            const response = await apiCall.getTropicalPastPapersByType(currentPaperType);
            if(response)
            {
                setError(false)
                setLoading(false)
                return response
            }
            else
            {
                setLoading(false)
                setError("Could not load data from server")
                return 
            }
    }

    useEffect(()=>{
        const getApiData = async()=>{
            const paperResponse = await getPapersByTypes()
            if(!error)
            {
                setPapers(paperResponse)
            }
        }

        getApiData()
    },[currentPaperType])


  return (
    <div className={`PastPaperCard-container py-0`}>
        <div className={`container-fluid my-3`}>
        
        <div className="blog-upper-content mb-0">
          <div className="row">
            <div  className={`col-lg-3`}>
              <div className={`blog-text-content text-lg-start text-start`}>
                
                <h3 className="h1_main">Past Papers</h3>   
              </div>
            </div>

            <div className={`col-lg-9`}>
              <div className="d-flex btn-container-admin justify-content-end align-items-center gap-4">
              {
                  Settings.enumTypes.map((type)=>{
                      return( 
                          <div className=" ">
                              <button className={`gen-btn-admin ${currentPaperType == type.value ? "gen-btn-active-admin" : ""}`}
                                  onClick={()=>{setCurrentPaperType(type.value)}}
                          >
                                  {type.label}
                                
                              </button>
                          </div>
                      )
                  })
              }
            
              

            </div>
            </div>
            
          </div>

          
      </div>

     {
      loading ?
        <Loader/>
      :
        <Swiper
          loop={true}
          spaceBetween={50}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
            
          }}
          modules={[Autoplay]}
          breakpoints={{
            280: {
              slidesPerView: 1,
            },
            992: {
              slidesPerView: 3,
            }
          }}
          className="card-list py-0 mx-0 w-100 px-2"
        > 
          {papers.map((paper, ind) => (
            <SwiperSlide key={ind} style={{ height: "auto" }}>
              <CardBox
                className="h-100"
                cardImg={"./images/creatives/course-card-img.jpg"}
                title={paper?.topical_paper_title}
                typeName={Settings.enumTypes.find((id)=> id.value === paper?.course_type_id)?.label}
                price={paper?.topical_paper_price}
                content={"Moosa Khan"}
                year={paper?.topical_paper_year}
                paperDetails={paper}
              />
            </SwiperSlide>
          ))}
        </Swiper>
     }
    </div>
 </div>

  )
}

export default PastPaperCard
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Links1, Links2, Links3 } from "../Navigationlinks";
import './Mobilenav.css'
import Modal from '../../components/Model/Modal';
import LoginSignup from "../LoginSignup/LoginSignup";
import Signup from "../SignupComponent/Signup";
import Settings from "../CommonAPICalls/Settings";
import { useSelector,useDispatch } from "react-redux";
import {logout} from "../../store/actions/userActions"
import CartPopup from "../LMS Portal/CartPopup/CartPopup";
import { showAlertMessage } from "../MasterComponents/CommonFunctionalities";

const Mobilenav = (props) => {
  const [selected, setSelected] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const [selected3, setSelected3] = useState(false);
  const [selected4, setSelected4] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginpressed, setLoginpressed] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenTopical, setIsDropdownOpenTopical] = useState(false);
  const [isDropdownOpenMore, setIsDropdownOpenMore] = useState(false);
  const [isDropdownOpenProfile, setIsDropdownOpenProfile] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [showCart, setShowCart] = useState(false)
  const closeModalCart = () => setShowCart(false);
  
  const userLoginStatus = useSelector((state)=> state.userLogin)
  const cartItems = useSelector((state)=> state.cartItems)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  
  const logoutHandler = () => {
    showAlertMessage("Logout Successfully","success")
    dispatch(logout());
    window.location.reload(false);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  
  const openSignUp = ()=>{
    setLoginpressed(false)
    setIsModalOpen(true)
  }
  const openLogin = ()=>{
    setLoginpressed(true)
    setIsModalOpen(true)
  }
  
  
  const dropdownRef = useRef();
  const dropdownTopical = useRef();
  const dropdownMore = useRef();
  const mainDropDownRef = useRef();
  const dropdownProfileRef = useRef();

  let transfer = props.transfer;
  const mobiledropdown = (state) => {
    setSelected(!state);
  };
  const mobiledropdown2 = (state) => {
    setSelected2(!state);
    setSelected3(false);
    setSelected4(false);
  };
  const mobiledropdown3 = (state) => {
    setSelected3(!state);
    setSelected2(false);
    setSelected4(false);
  };
  const mobiledropdown4 = (state) => {
    setSelected4(!state);
    setSelected3(false);
    setSelected2(false);
  };

  const services = [
    { name: "O Level ", link: "/notes/o-level" },
    { name: "AS Level", link: "/notes/as-level" },
    { name: "A Level", link: "/notes/a-level" },

  ];

  const topicals = [
    { name: "O Level ", link: "/topical-past-papers/o-level" },
    { name: "AS Level", link: "/topical-past-papers/as-level" },
    { name: "A Level", link: "/topical-past-papers/a-level" },
  ];

  const mores = [
    { name: "Insights ", link: "/news" },
    { name: "Contact", link: "/contact" },

  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        setIsDropdownOpenDesk(false);
      }
     
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <>
      <div
      style={{top: props.linear && "71px", zIndex:"900"}}
        className={
          transfer === true ? "  mobilenav_cs1" : "  mobilenavclicked_cs1"
        }
        ref={mainDropDownRef}
      >
        <ul>
          <div className={props.landingPage && 'd-none'}>
            <div className="d-flex justify-content-between align-content center navitems_cs1">
              <li style={{ color: "white" }}>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/"> Home </Link>
              </li>
              

              <i
                onClick={() => mobiledropdown(selected)}
                class="fa-solid fa-chevron-up"
                style={{
                  transform:
                    selected === true ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "0.3s",
                  color: selected === true ? "#CB1212" : "white",
                }}
              ></i>
            </div>
            {/* <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
              <li>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/about">About</Link>
              </li>
            </div> */}
            {/* <div className="d-flex justify-content-between align-content center navitems_cs1">
              <li style={{ color: "white" }}>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/portal"> Portal </Link>
              </li>
              

              <i
                onClick={() => mobiledropdown(selected)}
                class="fa-solid fa-chevron-up"
                style={{
                  transform:
                    selected === true ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "0.3s",
                  color: selected === true ? "#CB1212" : "white",
                }}
              ></i>
            </div> */}
            
            <div
              className={
                selected === true ? "mobsubmenu_cs1 show" : "mobsubmenu_cs1"
              }
            >
              
              <div onClick={() => mobiledropdown2(selected2)}>
                
                <h6 className="d-flex justify-content-between align-content-center">
                  
                  <li
                    style={{ color: selected2 === true ? "#CB1212" : "white" }}
                  >
                    Design & Build{" "}
                  </li>{" "}
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      transform:
                        selected2 === true ? "rotate(90deg)" : "rotate(45deg)",
                      transition: "0.3s",
                      color: selected2 === true ? "#CB1212" : "white",
                    }}
                  ></i>
                </h6>
                <div
                  className={
                    selected2 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  {" "}
                  <ul>
                    {Links1.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>{" "}
              <div onClick={() => mobiledropdown3(selected3)}>
                {" "}
                <h6 className="d-flex justify-content-between align-content-center">
                  {" "}
                  <li
                    style={{ color: selected3 === true ? "#CB1212" : "white" }}
                  >
                    {" "}
                    Create & Market{" "}
                  </li>
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      transform:
                        selected3 === true ? "rotate(90deg)" : "rotate(45deg)",
                      transition: "0.3s",
                      color: selected3 === true ? "#CB1212" : "white",
                    }}
                  ></i>
                </h6>
                <div
                  className={
                    selected3 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  <ul id="ul2_cs1">
                    {Links2.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>{" "}
              <div onClick={() => mobiledropdown4(selected4)}>
                <h6 className="d-flex justify-content-between align-content-center">
                  {" "}
                  <li
                    style={{ color: selected4 === true ? "#CB1212" : "white" }}
                  >
                    {" "}
                    Automate & Scale{" "}
                  </li>
                  <i
                    class="fa-solid fa-xmark"
                    style={{
                      transform:
                        selected4 === true ? "rotate(90deg)" : "rotate(45deg)",
                      transition: "0.3s",
                      color: selected4 === true ? "#CB1212" : "white",
                    }}
                  ></i>
                </h6>
                <div
                  className={
                    selected4 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  <ul id="ul2_cs1">
                    {Links3.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={props.landingPage && 'd-none'}>
          <div className="d-flex justify-content-between align-content center navitems_cs1">
              <li style={{ color: "white" }}>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/about"> About </Link>
              </li>
          </div>
          </div>
          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
          <div className="navigation-dropdown" ref={dropdownRef}>
                  <div className="dropdown-mobile-notes-trigger d-flex align-items-center" onClick={() => {setIsDropdownOpenDesk(!isDropdownOpenDesk) ; setIsDropdownOpenTopical(false)}}>
                    <span className="nav-links font-play"> Notes </span>
                    {/* <img src={`/images/icons/arrowrighticon.png ${isDropdownOpenDesk ? "b" : "w"}.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon" : "rotate-back arrow-icon"} alt="dropdown icon" /> */}
                  </div>    
                  <div className={`dropdown-mobile-notes-content ${isDropdownOpenDesk ? 'open' : 'd-none'}`}>
                    <div className="d-flex align-item-center gap-5">
                      <div>
                        {services.map((service, index) => (
                          <div key={index}>
                            <Link
                            
                              to={service.link}
                              className="nav-links font-play dropdown-item text-start"


                              onClick={() => { setIsDropdownOpenDesk(false); setMenuState(false); props.hamburgerdropdown(transfer)} }
                            >
                              <p className="itemsof-dropdown mb-0">{Settings.enumTypes[index]?.label}</p>

                            </Link>
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>

                </div>
          </div>
          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
            
          <div className="navigation-dropdown" ref={dropdownTopical}>
                  <div className="dropdown-mobile-trigger d-flex align-items-center" onClick={() => setIsDropdownOpenTopical(!isDropdownOpenTopical)}>
                    <span className="nav-links font-play"> Topical Past Paper </span>
                    {/* <img src={`/images/icons/arrowrighticon.png ${isDropdownOpenDesk ? "b" : "w"}.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon" : "rotate-back arrow-icon"} alt="dropdown icon" /> */}
                  </div>
                  <div className={`dropdown-mobile-topical-content  ${isDropdownOpenTopical ? 'open' : 'd-none'}`}>
                    <div className="d-flex align-item-center gap-5">
                      <div>
                        {topicals.map((topical, index) => (
                          <div key={index}>
                            <Link
                              to={topical.link}
                              className="nav-links font-play dropdown-item text-start"


                              onClick={() => { setIsDropdownOpenTopical(false); setMenuState(false); props.hamburgerdropdown(transfer)} }
                            >
                              <p className="itemsof-dropdown mb-0">{Settings.enumTypes[index]?.label}</p>

                            </Link>
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>

                </div>
          </div>

          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
              <li>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/courses">Courses</Link>
              </li>
            </div>
          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
              <li>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/contact">Contact</Link>
              </li>
            </div>
          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
              <li>
              <div className="d-flex align-items-start " style={{cursor:"pointer"}} onClick={() => { setShowCart(true) }}>
                      <img alt='cart icon' className='menu-icon' src='/images/icons/cart-icon-light.png' style={{height:"25px",width:"25px"}} />
                      <div className="cart-red-icon">{cartItems.length}</div>
                    </div>
              </li>
            </div>


          {/* <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
          <div className="navigation-dropdown" ref={dropdownMore}>
                  <div className="dropdown-mobile-trigger d-flex align-items-center" onClick={() => setIsDropdownOpenMore(!isDropdownOpenMore)}>
                    <span className="nav-links font-play"> More </span>
                  </div>
                  <div className={`dropdown-mobile-topical-content  ${isDropdownOpenMore ? 'open' : 'd-none'}`}>
                    <div className="d-flex align-item-center gap-5">
                      <div>
                        {mores.map((more, index) => (
                          <div key={index}>
                            <Link
                              to={more.link}
                              className="nav-links font-play dropdown-item text-start"


                              onClick={() => { setIsDropdownOpenMore(false); setMenuState(false); props.hamburgerdropdown(transfer)} }
                            >
                              <p className="itemsof-dropdown mb-0">{more.name}</p>

                            </Link>
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>

                </div>
                </div> */}
        
                {showCart &&
                    <Modal status={showCart}  >
                        <CartPopup closeModal={closeModalCart} />
                    </Modal>
                }
                {userLoginStatus.userInfo?.token ? (
                <>
                  <div className="d-flex align-items-center gap-1 user-profile-container pe-4">
                   
                    <p className="title mb-0 user" style={{color:"white"}}>{userLoginStatus.userInfo?.full_name}</p>

                    <div
                      className="navigation-dropdown"
                      ref={dropdownProfileRef}
                      onClick={() => {
                        setIsDropdownOpenProfile((prev)=>!prev);
                      }}
                    >
                      <div
                        className="dropdown-trigger d-flex align-items-center dropdown-item text-start"
                        
                      >
                        <img
                          alt="user icon"
                          className="menu-icon"
                          src="/images/icons/user-icon-light.png"
                        />
                        <img
                          src={`/images/icons/dropdown-icon-light.png`}
                          alt="arrow icon"
                          className={
                            isDropdownOpenProfile
                              ? "rotate-icon arrow-icon"
                              : "rotate-back arrow-icon"
                          }
                        />
                      </div>
                      <div
                        className={`dropdown-content ${
                          isDropdownOpenProfile ? "open" : "none-delay"
                        }`}
                        onClick={() => {
                          setIsDropdownOpenProfile((prev)=>!prev);
                        }}
                      >
                        <div
                          className="nav-links font-Open-Sans p-2"
                          onClick={() => {
                            setIsDropdownOpenProfile(false);
                            navigate("/portal")
                          }}
                        >
                          <p
                            className="mb-0 itemsof-dropdown"
                            style={{ textTransform: "uppercase" }}
                          >
                            Portal
                          </p>
                        </div>
                        <div
                          className="nav-links font-Open-Sans p-2"
                          onClick={() => {
                            logoutHandler()
                            setIsDropdownOpenProfile(false);
                          }}
                        >
                          <p
                            className="mb-0 itemsof-dropdown"
                            style={{ textTransform: "uppercase" }}
                          >
                            Logout
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
          <div className={`d-flex justify-content-start mobilebtn_cs1 ${props.landingPage && 'd-none'}`}>
            <a
              style={{ width: "70%" }}
              className="button2_cs_1"
              
            >
              <button
                style={{ width: "100%", borderRadius:"4px" }}
                className="button2_cs_1"
                href=""
                onClick={() => {
                  setLoginpressed(true);
                  openModal();
                }}>Login</button>
              <button
                style={{ width: "100%", borderRadius:"4px" }}
                className="button2_cs_1"
                href=""
                onClick={() => {
                  setLoginpressed(false);
                  openModal();
                }}>SignUp</button>
              <Modal status={isModalOpen} closeModal={closeModal}> {loginpressed? <LoginSignup closeModal={closeModal} openSignUp={openSignUp}/> :<Signup closeModal={closeModal} openLogin={openLogin}/>} </Modal> 
              
            </a>
          </div>
        )}
          {props.landingPage && 
            <div className={`d-flex justify-content-start mobilebtn_cs1`}>
              <a
                style={{ width: "70%" }}
                className="button2_cs_1" 
              >
                <button
                  style={{ width: "100%", borderRadius:"4px" }}
                  className="button2_cs_1"
                  href=""
                >
                  Chat With Us
                </button>
              </a>
            </div>
          }
          {props.landingPage && 
            <div className={`d-flex justify-content-start mobilebtn_cs1`}>
              <a
                style={{ width: "70%" }}
                className="button2_cs_1"
                href="tel: +1-647-860-5083"
              >
                <button
                  style={{ width: "100%", borderRadius:"4px" }}
                  className="button2_cs_1"
                  href=""
                >
                  Get Started
                </button>
              </a>
            </div>
          }
        </ul>
      </div>
    </>
  );
};

export default Mobilenav;

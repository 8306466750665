import React, { useState } from 'react'
import "./PaymentCheckoutForm.css"
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities'
import CommonCalls from '../CommonAPICalls/CommonCalls'
import { cartTotalPrice } from '../../store/reducers/cartItemsReducer'
import FieldsRenderer from '../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent'
import { validateForm } from '../UI Helpers/HelperFunctions/Helper'
import { useSelector } from 'react-redux'
import Settings from '../CommonAPICalls/Settings'

const copyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
  showAlertMessage("Copied to clipboard","success")
};

const inputsFieldAr = [
  {
    label: "Full Name",
    className: "col-lg-4",
    inputType: "text",
    name: "name",
    id: "name",
    required:true
  },
  {
    label: "Phone Number",
    className: "col-lg-4",
    inputType: "text",
    name: "phone",
    id: "phone",
    required:true
  },
  {
    label: "Email",
    className: "col-lg-4",
    inputType: "text",
    name: "email",
    id: "email",
    required:true
  },
  {
    label: "Transaction (from) Account Title",
    className: "col-lg-4 mt-lg-4 mt-0",
    inputType: "text",
    name: "amountTitle",
    id: "amountTitle",
    required:true
  },
  {
    label: "Transaction (from) Account Number",
    className: "col-lg-4 mt-lg-4 mt-0",
    inputType: "text",
    name: "accountNumber",
    id: "accountNumber",
    required:true
  },
]

function PaymentCheckoutForm({btnEnable}) { 
    const [formInputData , setFormInputData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error,setError] = useState(false)
    const cartItems = useSelector((state)=> state.cartItems);

    const apiCall = new CommonCalls(); 

      const updateUser = async()=>{
        if(cartItems?.length === 0 || !cartItems){
          showAlertMessage("Please add-up few courses in your cart, then try checking out!","fail");
          return;
        }

        setLoading(true)
        let validationResponse = validateForm(inputsFieldAr,formInputData);

        let formattedCartItems = cartItems?.map(item => `${item.title} / ${Settings.enumTypes?.find(val => val.value === item.type_id)?.label} / ${item.price}`).join('\n');
        let cartItemsTotalPrice = cartTotalPrice();
 
        if(!validationResponse)
        {
          setLoading(false)
          return
        }
        const userData ={
          full_name:formInputData?.name,
          email:formInputData?.email,
          phone_number:formInputData?.phone,
          transaction_account_number : formInputData?.accountNumber,
          transaction_account_title:formInputData?.amountTitle,
          purchased_items: formattedCartItems,
          total_transactional_amount: cartItemsTotalPrice
        }
        
        const response = await apiCall.paymentform(userData);
        if(response?.status === 200)
        {
            setError(false)
            setLoading(false)
            if(response?.length === 0)
            {
              showAlertMessage(response?.message,"fail")
              setError("Could not send data to server")
              return 
            }
            showAlertMessage(response?.message,"success")
            return response
        }
        else
        {
            setLoading(false)
            showAlertMessage("could not proceed please try again","fail")
            setError("Could not send data to server")
            return 
        }
      }
  return (
    <div className='PaymentCheckoutForm-contaienr py-5'>
        <div className='container'>
          <div className="row my-4 gy-4" >
            <div className='col-xxl-4 d-flex flex-lg-row flex-column align-items-center gap-3 ps-0'>
              <h3 className='total-text mb-0 color-red'>Account Title: </h3>
              <p className='mb-0 bank-details-text'>MOOSA BIN HASHMAT</p>
              <div onClick={()=>{copyToClipboard("MOOSA BIN HASHMAT")}} className='copy-btn'><img src='/images/icons/copy-icon.png' alt='copy icon'/></div>
            </div>
            <div className='col-xxl-4 d-flex flex-lg-row flex-column align-items-center gap-3 ps-0'>
              <h3 className='total-text mb-0 color-red'>Account Number: </h3>
              <p className='mb-0 bank-details-text'>01330103999073</p>
              <div onClick={()=>{copyToClipboard("01330103999073")}} className='copy-btn'><img src='/images/icons/copy-icon.png' alt='copy icon'/></div>
            </div>
            <div className='col-xxl-4 d-flex flex-lg-row flex-column align-items-center gap-3 ps-0'>
              <h3 className='total-text mb-0 color-red'>IBAN Number: </h3>
              <p className='mb-0 bank-details-text'>PK90MEZN0001330103999073</p>
              <div onClick={()=>{copyToClipboard("PK90MEZN0001330103999073")}} className='copy-btn'><img src='/images/icons/copy-icon.png' alt='copy icon'/></div>
            </div>
          </div>
        <form onSubmit={(e)=>{
              e.preventDefault()
              updateUser()
            }}>
              <div className='container'>
                <div className="row my-2 gy-4">
                <FieldsRenderer
                  fieldsArray={inputsFieldAr}
                  updateState={setFormInputData}
                  currentState={formInputData}
                />
                  {/* <div className="col-md-6">
                      
                    <input
                      type="text"
                      className="profile-form-field mt-1"
                      placeholder="Full Name"
                      value={name}
                      onChange={(e)=>{setName(e.target.value)}}
                    />
                  </div>
                
                  <div className="col-md-6">
                    
                    <input
                      type="text"
                      className="profile-form-field mt-1"
                      placeholder="Email" 
                      value={email}
                      onChange={(e)=>{setEmail(e.target.value)}}
                    />
                  </div>
                  <div className="col-md-6">
                    
                    <input
                      type="text"
                      className="profile-form-field mt-1"
                      placeholder="Contact Number"
                      value={phone}
                      onChange={(e)=>{handlePhoneChange(e)}}
                    />
                  </div>
                  <div className="col-md-6">
                    
                    <input
                      type="text"
                      className="profile-form-field mt-1"
                      placeholder="Transaction Account Title"
                      value={amountTitle}
                      onChange={(e)=>{setAmountTitle(e.target.value)}}

                    />
                  </div>
                  <div className="col-md-12">
                    
                    <input
                      type="text"
                      className="profile-form-field mt-1"
                      placeholder="Transaction Account Number"
                      value={accountNumber}
                      onChange={(e)=>{setAccountNumber(e.target.value)}}

                    />
                  </div> */}
                  <div className="col-md-6 ps-0">
                    
                    <button className="genral-btn mt-4" type="submit" disabled={loading||!btnEnable||cartTotalPrice() == 0}>
                    <div><span> Proceed </span> {loading ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                    </button>
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-center">
                    
                    <p className='mb-0 total-text'>Total Transactional Amount: <span className='color-red total-text' style={{whiteSpace:"nowrap"}}>PKR {cartTotalPrice()} /-</span></p>
                  </div>
                </div>
              </div>
              
            </form>

        </div>
    </div>

  )
}

export default PaymentCheckoutForm
import React from 'react';
import Mission from '../../components/MissionAndMission/Mission';
import Story from '../../components/MyStory/Story';
import MyServices from '../../components/MyServices/MyServices'; 
import PlainHero from '../../components/PlainHero/PlainHero';
import TestimonialForm from '../../components/TestimonialForm/TestimonialForm';
import AboutUs from '../../components/AboutUs/aboutUs';
  

const AboutPage = () => {
  return (
    <div>  
        <PlainHero
          heading="Moosa Khan"
          subHeading="ABOUT"
          content="Moosa Khan is well known for inspiring and educating thousands of chemistry students. With a passion for teaching, he ensures that his classes are engaging and comprehensive. His approachable nature and commitment to students' success creates a supportive learning environment."
        />
 
        <div style={{backgroundImage:"url('/images/creatives/about-composit-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
        <AboutUs 
          btn={true}
        />
        {/* <Mission /> */}
        <MyServices />
        <Story />
        <TestimonialForm/>
        </div> 
    </div>
  )
}

export default AboutPage
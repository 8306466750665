import React,{useState} from "react";
import "./Contactform.css";
import { useNavigate } from "react-router-dom";
import FieldsRenderer from "../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import { validateForm } from "../../UI Helpers/HelperFunctions/Helper";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Settings from "../../CommonAPICalls/Settings";

const inputsFieldAr = [
  {
    label: "First Name",
    className: "col-lg-6",
    inputType: "text",
    name: "first_name",
    id: "first_name",
    required:true
  },
  {
    label: "Last Name",
    className: "col-lg-6",
    inputType: "text",
    name: "last_name",
    id: "last_name",
    required:true
  },
  {
    label: "Phone Number",
    className: "col-lg-6",
    inputType: "text",
    name: "phone_number",
    id: "phone_number",
    required:true
  },
  {
    label: "Email",
    className: "col-lg-6",
    inputType: "text",
    name: "email",
    id: "email",
    required:true
  },
  {
    label: "Level of Study",
    className: "col-lg-6",
    inputType: "dropdown",
    name: "level_of_study",
    id: "level_of_study",
    options: Settings.contactFormStudyLevelOptions,
    required:true
  },
  {
    label: "Current School/College/Private",
    className: "col-lg-6",
    inputType: "text",
    name: "current_campus",
    id: "current_campus",
    required:true
  },
  {
    label: "Message",
    className: "col-lg-12",
    inputType: "text",
    name: "message",
    id: "message",
    required:true
  },
]

const apiCall =new CommonCalls()

const Contactform = () => {
  const [formInputData , setFormInputData] = useState({})
  const [saveBtn , setSaveBtn] = useState(false)

  const onSubmitButtonClick = async () => {
    setSaveBtn(true)
                    
    let validationResponse = validateForm(inputsFieldAr,formInputData);
    if(!validationResponse) {
      setSaveBtn(false)
      return
    }
    const response = await apiCall.sendQuestionForm({
      ...formInputData,
      level_of_study: Settings.contactFormStudyLevelOptions?.find((obj) => obj?.value == formInputData?.level_of_study)?.label
    });

    if(response?.status == 201) { 
      showAlertMessage(response?.message, "success");
      setTimeout(() => {
        window.location.reload();
        setSaveBtn(false);
      }, 1200);
    }
    else {
      setSaveBtn(false);
      showAlertMessage(response?.message, "fail");
    }
  }
  return (
    <div
      className="contact_section"
    >
      <div className="container">
        <div className="row gy-4 gx-lg-5 d-flex align-items-center">
          <div className="col-md-6 " >
            <div className="contact-details" >
              <p className="h2_main">Let's Talk</p>
              <h2 className="h1_main mb-2 ">
              Tackle your chemistry fears
              </h2>
              <p className="para_main">
              Ready to start your O level/IGCSE and A level journey with us? Enroll yourself as soon as possible. Our representative will contact you soon and guide you through the next steps. Don’t miss out on this opportunity to be a chemistry expert!
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row gy-4">

              <div className="col-12"> 
                <div className="contact_form_form_container">  
                  <FieldsRenderer
                      fieldsArray={inputsFieldAr}
                      updateState={setFormInputData}
                      currentState={formInputData}
                    />
                  <div className="row mt-1">
                    <div className="col-12 button_col d-flex align-items-center justify-content-start">
                      <button onClick={onSubmitButtonClick} className="connect-button w-100" disabled={saveBtn}>
                        <div><span> Submit </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                      </button> 
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactform;

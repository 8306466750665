import React from 'react';
import styles from './StartToSuccessComponent.module.css';
import { useNavigate } from 'react-router-dom';

const StartToSuccessComponent = () => {
  const navigate = useNavigate()
  return (
      <div className={styles.successcontainer}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 text-center text-lg-start mb-0 mb-lg-3 left-section">
          <h2 className='h2_main'>Start to Success</h2>
          <h3 className='h1_main'>Why study with Sir Moosa?</h3>
          <p className='para_main'>Sir Moosa simplifies the toughest of concepts, making them easy to understand. With convenient access to a wide range of resources, he offers a one-stop solution for all your chemistry needs. Join his classes and experience a unique approach to acing chemistry.</p>
        </div>
        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
          <div className="btn-container">
            <button className="connect-button" onClick={()=>{navigate("/courses")}}>Enrolled Now</button>
          </div>
        </div>



      </div>

      <div className={styles.testimonials}>
        <div className={styles.testimonial}>
          <div className={styles.imageWrapper}>
            <img src="/images/ideas1-new.svg" alt="Testimonial" style={{height:"50px",width:"50px"}}/>
          </div>
          <h3 className='h3_main'>Recorded Lectures</h3>
          <p className='para_main'> Apart from live lessons, we offer recorded lectures to ensure flexible learning. Enroll yourself to access these lectures, where complex concepts are explained with clarity.</p>
        </div>
        <div className={styles.testimonial}>
          <div className={styles.imageWrapper}>
            <img src="/images/ideas2-new.svg" alt="Testimonial" style={{height:"50px",width:"50px"}}/>
          </div>
          <h3 className='h3_main'>Comprehensive notes</h3>
          <p className='para_main'>Learn from our detailed notes, carefully organized to cover every aspect of chemistry. Whether you’re studying the basics or advanced level chemistry topics, these notes will provide clear and concise explanations.</p>
        </div>
        <div className={styles.testimonial}>
          <div className={styles.imageWrapper}>
            <img src="/images/ideas3-new.svg" alt="Testimonial" style={{height:"50px",width:"50px"}}/>
          </div>
          <h3 className='h3_main'>Rigorous Past Paper Practice</h3>
          <p className='para_main'>Our curriculum includes extensive topical and yearly past paper practice to strengthen your knowledge and understanding. By regularly attempting exam questions, you can get familiar with the exam format and tackle different types of questions.</p>
        </div>
        {/* <div className={styles.testimonial}>
          <div className={styles.imageWrapper}>
            <img src="/images/ideas1.svg" alt="Testimonial" style={{height:"50px",width:"50px"}}/>
          </div>
          <h3 className='h3_main'>Monthly Assessments</h3>
          <p className='para_main'>We conduct monthly assessments to ensure consistent progress and identify areas needing improvement. After each chapter, get tested, receive your scores and track your progress with detailed feedback.</p>
        </div> */}
      </div>
      </div>

    </div>
  );
};

export default StartToSuccessComponent;

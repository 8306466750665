import React from 'react';
import './MyServices.css';
import { Container, Row, Col, Button } from 'react-bootstrap'; // Import Bootstrap components
import { useNavigate } from 'react-router-dom';

const cardData = [
  {
    icon: "/images/commitment-icon.svg",
    title: "DEDICATION",
    content: "I am devoted to making chemistry fun and engaging for every student. Beginning from challenges or disinterest, my goal is to inspire enthusiasm for the subject through individual attention and unique teaching strategies.",
    link: "#",
  },
  {
    icon: "/images/accessibility.svg",
    title: "PASSION",
    content: "I am deeply passionate about my subject and teaching style, which is evident in the interactive discussion sessions I hold with my students. These sessions encourage active participation, quick thinking and a deeper understanding of complicated concepts.Through these sessions, pupils not only grasp the topics being taught but also develop communication skills and confidence in expressing their ideas.",
    link: "#",
  },
  {
    icon: "/images/commitment-icon.svg",
    title: "POTENTIAL",
    content: "One of my most significant values is the belief that every child is unique with hidden potential waiting to be discovered. I am devoted to boosting this potential, creating an atmosphere where every student can prosper. My commitment ensures every student's success, as I work tirelessly to help them achieve their fullest capabilities.",
    link: "#",
  },
  {
    icon: "/images/accessibility.svg",
    title: "COLLABORATION",
    content: "I value collaborating with parents to ensure each student's success. By maintaining constant communication and working together, we create a supportive environment where we can discuss academic challenges and reinforce learning at home. This teamwork allows me to better understand and support students' individual needs, enhancing their overall educational experience.",
    link: "#",
  },
];

function ServiceCard({ icon, title, content, link = "#" }) {
  const navigate = useNavigate();

  return (
    <div className='service-card-container mb-4'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 icon-container justify-content-center d-flex'>
            <img
              className='img-fluid values-image'
              alt='service icon'
              src={icon}
              onClick={() => { navigate(link) }}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className='col-lg-9'>
            <h3 className={`card-title h3_main mb-3 ${title === "Branding" ? "color-red" : "color-white"}`}>{title}</h3>
            <p className='body-paragraph card-content my-1 para_main'>{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function MyServices() {
  const navigate = useNavigate()
  return (
    <div className='MyServices-container'>
      <Container fluid className="container py-5">
        <Row className="justify-content-center">
          <Col lg={6} md={6} sm={12} className="sliding-card">
            <div className='card-sized-box'>
              {cardData.map((ele, index) => (
                <ServiceCard
                  key={index}
                  icon={ele.icon}
                  title={ele.title}
                  content={ele.content}
                  link={ele.link}
                />
              ))}
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="text-content text-center text-lg-start">
            <h2 className="h2_main">OUR VALUES</h2>
            <h1 className="h1_main">My Work Values</h1>
            <div className="text-container">
              <p className="para_main">I am dedicated to ensuring student success, through creating a supportive learning environment and providing high quality education. A friendly class atmosphere encourages pupils to participate and promotes a positive learning experience.</p>
              <button className="connect-button" onClick={()=>{navigate("/courses")}}>Explore Courses</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MyServices;

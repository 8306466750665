import React, { useState } from 'react';
import './CommonComponentsStylings.css';

const RadioGroup = ({ id, options, onChange, name, label, required }) => {
  
  const [checkedState, setCheckedState] = useState(
    options.map((option) => ({
      label: option.label,
      isChecked: option.isChecked || false,
      inputValue: '',
      isOther: option.isOther ?? false
    }))
  );

  const handleRadioChange = (index, event) => {
    const newCheckedState = checkedState.map((item, idx) => ({
      ...item,
      isChecked: idx === index
    }));

    setCheckedState(newCheckedState);

    if (onChange) {
      onChange(event, newCheckedState, name);
    }
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    const newCheckedState = checkedState.map((item, idx) =>
      idx === index
        ? { ...item, inputValue: value }
        : item
    );

    setCheckedState(newCheckedState);

    if (onChange) {
      onChange(event, newCheckedState, name);
    }
  };

  return (
    <div className="radio-group ">
      <label htmlFor={id} style={{fontWeight:"500"}}>
        {label}{required && <span style={{ color: 'red' }}>*</span>}
      </label>
      {options.map((option, index) => (
        <div key={index} className="radio-wrapper my-2 ms-2 d-flex align-items-center">
          <input
            type="radio"
            id={`radio-${index}`}
            name={name}
            disabled={option.disabled}
            checked={checkedState[index].isChecked}
            onChange={(event) => handleRadioChange(index, event)}
            className='me-2'
            style={{width:"20px",height:"20px"}}
          />
          <label htmlFor={`radio-${index}`}>
            {option.label}{option.required ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>

          {option.isOther && checkedState[index].isChecked && (
            <input
              type="text"
              value={checkedState[index].inputValue}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Please specify..."
              className="other-text-input ms-2"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;

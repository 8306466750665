import React,{useState,useEffect} from 'react'
import PlainHero from "../../components/PlainHero/PlainHero"
import Curriculum from "../../components/Curriculam/Curriculum"
import { useNavigate, useParams } from 'react-router-dom';
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from '../../components/CommonAPICalls/Settings';
import Loader from '../../components/UI Helpers/Simple Loading/Loader';
import { useSelector } from 'react-redux';
import { showAlertMessage } from '../../components/MasterComponents/CommonFunctionalities';

const apiCall = new CommonCalls();

function CurriculumPage() {
  const [course,setCourse] = useState()
  const [loading, setLoading] = useState(true)
  const [error,setError] = useState(false)

  const id  = useParams().id;
  const navigate = useNavigate()

  const getCoursesBySlug = async()=>{
    setLoading(true)
    const response = await apiCall.getCourseBySlug(id);
    if(response)
      {
          setError(false)
          setLoading(false)
          return response
      }
      else
      {
          setLoading(false)
          showAlertMessage("Could not load data from server","fail")
          setError("Could not load data from server")
          return 
      }
  }
  
  useEffect(() => {
    const getApiData = async()=>{
      const courseResponse = await getCoursesBySlug()
      
      if(!error)
      {
          setCourse(courseResponse)
      }
    } 
    getApiData()
  }, [id]);
  return (
    <>
    {loading ?
    <div style={{minHeight:"80vh",height:"450px"}} className='d-flex align-items-center justify-content-center'>
      <Loader/>
    </div>
    :
    <>
      {
        course 
        &&
        <>
          <PlainHero
              heading={`${Settings.enumTypes.find(id => id.value === course?.course_type_id)?.label} - ${course?.course_name}`}
              content="Sir Moosa Khan  |  Last Update March 6 2024"
              // enrolledText='50 already enrolled'
          />
          <div style={{backgroundImage:"url('/images/creatives/course-preview-composite-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
            <Curriculum
              courseDetails={course}
            />
          </div>
        </>
      }
    </>  
  }
    </>
  )
}

export default CurriculumPage

import './CardBox.css';

function CardBox(props) {
    return (
      <div className="card-box">
          <img src={props.cardImg} alt="someImage" />
          <div className="card-text-content text-center">
            <div className="top-links">
              {/* <div className="blue-box">
                <p className="m-0">All Levels</p>
              </div> */}
              {/* <div className="d-flex align-items-center justify-content-start gap-1">
                <img src="/images/icons/red-star-icon.svg" className="m-0"/>
                <p className="m-0">5.0 Ratings</p>
              </div> */}
            </div>
            <p className="mb-2 red-text mb-0">{props.content}</p>
            <h5 className="title mb-0">{props.title}</h5>

            <div className="d-flex justify-content-lg-between justify-content-start align-items-lg-center align-items-start mb-3 buttons-area">
                {/* <h5 className="title mb-0">{props.price}</h5> */}
                <button className="load-more w-100">Load More</button>
                <button className="connect-button w-100">Buy Now</button>
            </div>
          </div>
      </div>
    )
  }
  
  export default CardBox
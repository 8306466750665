import { showAlertMessage } from "../../components/MasterComponents/CommonFunctionalities";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('cart');
    return serializedState ? JSON.parse(serializedState) : [];
  } catch (err) {
    console.error('Could not load state from local storage', err);
    return [];
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('cart', serializedState);
  } catch (err) {
    console.error('Could not save state to local storage', err);
  }
};

const initialState = loadState();
 
export const cartTotalPrice = () => {
 const cartItems = loadState()
 if(cartItems.length == 0)
 {
  return 0
 }
 else{
  let totalPrice = 0;
  cartItems.map((item)=> totalPrice+=item?.price)
  return Math.round(totalPrice);
 }
};
const cartItemsReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case "ADD_TO_CART":
      if (action.purchaseDetails?.enrolled_courses?.includes(action.payload.course_id) || state.find(item => item._id === action.payload?._id)) {
        showAlertMessage("Item already in Cart")
        return state;
      }
      else if(action.payload?.course_id)
      {
        newState = [...state, {
          _id: action.payload?._id,
          title:action.payload?.course_name,
          type_id:action.payload?.course_type_id,
          slug_url:action.payload?.slug_url,
          level: action.payload?.course_level,
          total_lessons: action.payload?.course_total_lessons,
          description: action.payload?.course_description,
          price: action.payload?.course_price,
          course_id: action.payload?.course_id
        }];
        showAlertMessage("Added item","success")
        saveState(newState);
        return newState;
      }
      else if(action.payload?.past_paper_id)
      {
        newState = [...state, {
          _id: action.payload?._id,
          title:action.payload?.topical_paper_title,
          type_id:action.payload?.past_paper_type_id,
          slug_url:null,
          level: null,
          total_lessons: null,
          description: action.payload?.topical_paper_description,
          price: action.payload?.topical_paper_price,
          course_id: action.payload?.past_paper_id
        }];
        showAlertMessage("Added item","success")
        saveState(newState);
        return newState;
      }
      newState = [...state, action.payload];
      saveState(newState);
      return newState;

    case "REMOVE_FROM_CART":
      newState = state.filter(item => item._id !== action.payload);
      saveState(newState);
      return newState;

    case "CLEAR_CART":
      newState = [];
      saveState(newState);
      return newState;

    default:
      return state;
  }
};

export default cartItemsReducer;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter, getDateFormatted } from "../../MasterComponents/CommonFunctionalities"; 
import { showAlertMessage, checkIfArrayIsEmpty } from '../../MasterComponents/CommonFunctionalities';

const SystemExamsListings = () => {
  const [examsData, setExamsData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const apiCalls = new CommonCalls();

  const navigate = useNavigate();

  useEffect(() => { getAllSystemExams(); }, []);

  const getAllSystemExams = async () => { 
    try {
      const apiResponse = await apiCalls.getExamDetails();
      if (apiResponse) {
        setExamsData(apiResponse?.data);
        setTotalRecords(apiResponse?.data?.length);
        setLoading(false);
      }
    } catch (err) {
      setExamsData([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onTopicClicked = (row, colKey) => {
    if(colKey === 'exam_topic'){
      navigate(`/portal/system-exams?examid=${row?.exam_id}`);
    }  
  };

  const onDeleteSystemExams = async (rowItems) => {
    if(checkIfArrayIsEmpty(rowItems)) {
      showAlertMessage('Please select any rows to delete!', 'fail');
      return;
    };

    setLoading(true);

    for (const item of rowItems) {   
      try {
        const apiResponse = await apiCalls.removeSystemExams(item?.exam_id);
        if(apiResponse) {
          showAlertMessage(apiResponse?.message, 'success');
        }
      } catch (error) {
        showAlertMessage(error, 'fail');
      }
    };

    getAllSystemExams();
  };

  const gridColumnHeaders = [
    { field: 'exam_topic', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Exam Topic' },
    { field: 'marks_out_of', columnName: 'Marks Out Of' },
    { field: 'attendance_out_of', columnName: 'Attendance Out Of' },
    { field: 'exam_date', cellRenderer: getDateFormatted, customRendererLink: true, columnName: 'Exam Date' }
  ];

  return (
    <div className='listings_view_wrapper'> 
      <h1 className="h1_main">All System Exams</h1>

      <CustomDataGrid 
        columns={gridColumnHeaders} 
        data={examsData} 
        pageSize={pageSize} 
        loadingState={loading}
        pagination={false}
        onAddClick={() => navigate('/portal/system-exams')}
        onCellClicked={onTopicClicked}
        onSelectedRowsDelete={onDeleteSystemExams}
        totalRecords={totalRecords}
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        onPageSizeChange={setPageSize}
      />
    </div>
  )
}

export default SystemExamsListings

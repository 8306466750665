import React,{useState,useEffect} from "react";
import "./CourseCard.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from "../CommonAPICalls/Settings";
import Loader from "../UI Helpers/Simple Loading/Loader";
import { useSelector } from "react-redux";

const cardDataOlevel = [
    {
      cardImg: "./images/creatives/course-card-img.jpg",
      title: "O LEVEL CHEMISTRY ATP SESSION",
      price:"PKR 1,500",
      content:"Moosa Khan",
    },
    {
      cardImg: "./images/creatives/course-card-img.jpg",
      title: "O LEVEL CHEMISTRY ATP SESSION",
      price:"PKR 1,500",
      content:"Moosa Khan",
    },
    {
      cardImg: "./images/creatives/course-card-img.jpg",
      title: "O LEVEL CHEMISTRY ATP SESSION",
      price:"PKR 1,500",
      content:"Moosa Khan",
    },
    {
      cardImg: "./images/creatives/course-card-img.jpg",
      title: "O LEVEL CHEMISTRY ATP SESSION",
      price:"PKR 1,500",
      content:"Moosa Khan",
    },
   
    
  ];


function CourseCard({adminCourse=false}) {
    const [currentCourseType , setCurrentCourseType] = useState(Settings.enumTypes[0]?.value)
    const [courses,setCourses] = useState([])
    const [loading, setLoading] = useState(false)
    const [error,setError] = useState(false)
    const userLogin = useSelector((state)=>state.userLogin).userInfo

    const apiCall = new CommonCalls();
    const getCourseByTypes = async()=>{
            setLoading(true)
            const response = await apiCall.getSystemCoursesByType(currentCourseType,userLogin?.enrolled_courses);
            if(response)
            {
                setError(false)
                setLoading(false)
                return response
            }
            else
            {
                setLoading(false)
                setError("Could not load data from server")
                return 
            }
    }

   

    useEffect(()=>{
        const getApiData = async()=>{
            const coursesResponse = await getCourseByTypes()
            
            if(!error)
            {
                setCourses(coursesResponse.filter((course)=> !course?.free_view))
            }
        }
        getApiData()

    },[currentCourseType])


  return (
    <div className={`CourseCard-container ${adminCourse ?"py-0":"py-5"} `}>
        <div className={` ${adminCourse ? 'container-fluid my-3' : 'container my-5'}`}>
        {adminCourse ?
        <div className="blog-upper-content mb-0">
          <div className="row">
            <div  className={`${adminCourse ? "col-lg-3":"col-lg-6"}`}>
              <div className={`blog-text-content text-lg-start ${adminCourse? 'text-start' : 'text-center'}`}>
                
                <h3 className="h1_main">Courses</h3>   
              </div>
            </div>

            <div className={`${adminCourse ? "col-lg-9":"col-lg-6"}`}>
              <div className="d-flex btn-container-admin justify-content-end align-items-center gap-4">
              {
                  Settings.enumTypes.map((type)=>{
                      return( 
                          <div className=" ">
                              <button className={`gen-btn-admin ${currentCourseType == type.value ? "gen-btn-active-admin" : ""}`}
                                  onClick={()=>{setCurrentCourseType(type.value)}}
                          >
                                  {type.label}
                                
                              </button>
                          </div>
                      )
                  })
              }
            
              

            </div>
            </div>
            
          </div>

          
      </div>
      :
        <div className="blog-upper-content mb-5">
          <div className="row">
            <div className={`col-12`}>
              <div className="blog-text-content text-center">
                
                <h3 className="h1_main">O-Levels | A2-Levels | AS-Levels</h3>   
                <h5 className="h1_main">Chemistry Courses</h5>
                
              </div>
            </div>
            
          </div>

          <div className="row btn-container mx-auto ">
            {
                Settings.enumTypes.map((type)=>{
                    return( 
                        <div className="col-lg-4 ">
                            <button className={`gen-btn ${currentCourseType == type.value ? "gen-btn-active" : ""}`}
                                onClick={()=>{setCurrentCourseType(type.value)}}
                        >
                                {type.label}
                            </button>
                        </div>
                    )
                })
            }
          </div>
      </div>
        }

     {
      loading ?
        <Loader/>
      :
      <>
        {
          courses.length>0 ?
          <>
          {
            adminCourse ? 
            <div className="container" style={{maxHeight:"450px",overflowY:"auto"}}>
              <div className="row gy-3">
                  {
                    courses.map((course,ind)=>{
                      return(
                        <div className="col-xl-4 col-lg-6 col-sm-12">
                            <CardBox
                              className="h-100"
                              cardImg={"./images/creatives/course-card-img.jpg"}
                              title={course?.course_name}
                              typeName={Settings.enumTypes.find((id)=> id.value === course?.course_type_id)?.label}
                              price={course.course_price}
                              content={"Moosa Khan"}
                              slug_url={course?.slug_url}
                            />
                        </div>
                      )
                    })
                  }

              </div>
            </div>
            :
            <Swiper
              loop={true}
              spaceBetween={50}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
                
              }}
              modules={[Autoplay]}
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
              className="card-list py-0 mx-0 w-100 px-2"
            > 
              {courses.map((course, ind) => (
                <SwiperSlide key={ind} style={{ height: "auto" }}>
                  <CardBox
                    className="h-100"
                    cardImg={"./images/creatives/course-card-img.jpg"}
                    title={course?.course_name}
                    typeName={Settings.enumTypes.find((id)=> id.value === course?.course_type_id)?.label}
                    price={course.course_price}
                    content={"Moosa Khan"}
                    slug_url={course?.slug_url}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          }
        </>
          :
          <h3 className="text-center py-5">No Courses Found Under This Category</h3>
        }
      </>
     }
    </div>
 </div>

  )
}

export default CourseCard
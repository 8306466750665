import Settings from "../../components/CommonAPICalls/Settings";
import SystemApiCallSettings from "../../components/CommonAPICalls/SystemApiCallSettings";
import { showAlertMessage } from "../../components/MasterComponents/CommonFunctionalities";
import CommonCalls from '../../components/CommonAPICalls/CommonCalls'; 
  
export const handleLoginSuccess = (dispatch, loginResponseData=[],showMessage=true, userResponseData=[], moduleResponseData=[]) => {
  if(showMessage)
  {
    showAlertMessage("User logged in successfully! You can now access all FREE courses & notes","success")
  }
  const payload = {
    user_id: loginResponseData?.user_details?.user_id,
    token: loginResponseData?.token,
    email: loginResponseData?.user_details?.email,
    enrolled_courses:loginResponseData?.user_details?.enrolled_courses,
    enrolled_past_papers: loginResponseData?.user_details?.enrolled_past_papers, 
    full_name: loginResponseData?.user_details?.full_name,
    is_admin: loginResponseData?.user_details?.is_admin,
    portal_access: loginResponseData?.user_details?.portal_access,
  };

  dispatch({
    type: "USER_LOGIN_SUCCESS",
    payload,
  });

  localStorage.setItem("userInfo", JSON.stringify({
    user_id: payload?.user_id,
    full_name: payload?.full_name,
    token:payload?.token
  }));
};

export const fetchUserDetails = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "USER_LOGIN_FETCH",
      });

      const response = await new CommonCalls().getUserDetails();
      const responseData = response?.response
      
      if (responseData?.status === 200) {
        handleLoginSuccess(dispatch, {
          token: response?.token,
          user_details: { ...responseData?.data }
        }, false);
      }
      else
      {
        dispatch(logout())
      }
    } catch (error) {
      dispatch({
        type: "USER_LOGIN_FAIL",
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
};
export const checkUser = () => {
  return async (dispatch) => {
    try {

      const response = await new CommonCalls().getUserDetails();
      const responseData = response?.response
      
      if (responseData?.status === 200) {
        return true
      }
      else
      {
        dispatch(logout())
      }
    } catch (error) {
      dispatch({
        type: "USER_LOGIN_FAIL",
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
};
export const login = (userLogin) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "USER_LOGIN_REQUEST",
      });

      const loginResponseData = await SystemApiCallSettings('POST', Settings.apiUserUrl + '/login', {}, true, userLogin);
      
      if (loginResponseData?.status === 200) {
        handleLoginSuccess(dispatch, loginResponseData);
        return true
      }else if(loginResponseData?.status === 429) { 
        showAlertMessage(loginResponseData?.message,"fail")
        return ; 
      }
    } catch (error) {
      dispatch({
        type: "USER_LOGIN_FAIL",
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
};

export const logout = () => {  
  return (dispatch) => {
    localStorage.removeItem("userInfo");
    dispatch({ type: "USER_LOGOUT" });
    dispatch({ type: "USER_DETAILS_RESET" });
  };
}; 


import React, { useState } from "react";
import "./LoginSignup.css";
import {  useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {login} from "../../store/actions/userActions"
import FieldsRenderer from "../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent";
import { encryptPassword, validateForm } from "../UI Helpers/HelperFunctions/Helper";


const LoginSignup = ({closeModal,openSignUp}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [formInputData,setFormData] = useState("")
  const [saveBtn , setSaveBtn] = useState(false)

  const inputsFieldAr = [
    {
      label: "Email",
      className: "col-lg-12",
      inputType: "text",
      name: "email",
      id: "email",
      required:true
    },
    {
      label: "Password",
      className: "col-lg-12",
      inputType: "password",
      name: "password",
      id: "password",
      required:true
    },
  ];
  return (
    <div className="container">
      <div className={`row no-gutters`}>
        
        <div className="col-lg-6 login-popup-section">
          <div className="row">
          <div className="col-12">
              <div className="img-modal text-end d-block d-lg-none"> 
                <img onClick={closeModal}
                  src="/icons/icon-cross.png"
                  alt="cross-icon"
                  class="icon-cross"
                /> 
              </div>
              </div>
            
            <div className="col-12">
              <div className="login-popup-box">
                

                <h2 className="h1_main">Login</h2>
                
                
                <form 
                  onSubmit={async (e)=>{
                    e.preventDefault();
                    setSaveBtn(true)
                    let validationResponse = validateForm(inputsFieldAr,formInputData);
                    if(!validationResponse)
                    {
                      setSaveBtn(false)
                      return
                    }
                    try{
                      const resp = await dispatch(login({ email: formInputData.email, password: encryptPassword(formInputData.password) }));
                      setSaveBtn(false)
                      if(resp)
                      {
                        closeModal(true)
                      }
                    }
                    catch(e)
                    {
                      setSaveBtn(false)
                    }
                  }}
                >
                  <div className="form-group">
                    <FieldsRenderer
                      fieldsArray={inputsFieldAr}
                      updateState={setFormData}
                      currentState={formInputData}
                    />
                  </div>
                
                  <button type="submit" className="connect-button w-100 mt-3" disabled={saveBtn}>
                    <div><span> Login </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                  </button>
                </form>
              </div>
            </div>
          </div> 
        </div>
        <div className="col-lg-6 signup-popup-section d-none d-lg-block">
        <div className="img-modal text-end d-none d-lg-block"> 
                <img onClick={closeModal}
                  src="/icons/cross-icon.png"
                  alt="cross-icon"
                  class="icon-cross"
                  style={{cursor:"pointer"}}
                /> 
              </div>
          <div className="signup-popup-box">
            <h2>Hello Friends,</h2>
            <p>
             Create a new account
            </p>
            <button className="btn btn-outline-light"
              onClick={openSignUp}
            >Sign Up</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;

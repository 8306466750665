import React from 'react'
import "./Sponsor.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Sponsor() {
  return (
    <div className='sponsor-container  standard-margin-space py-1'>
      <div className='container'>
        
          
      <div className='py-2 mt-4'></div>
        <div className="companies-logo-box ">
        <Swiper
            spaceBetween={50}
            autoplay={{
              delay: 4500,
              disableOnInteraction: true,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1000: {
                  slidesPerView: 4,
                },
                1300: {
                  slidesPerView: 5,
                },
              }}
            className=""
            >
                <SwiperSlide>
                <img src="/images/icons/partner-1.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-2.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-3.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-4.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-5.png" alt="" />
                </SwiperSlide>
                
                <SwiperSlide>
                <img src="/images/icons/partner-6.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-7.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-8.png" alt="" />
                </SwiperSlide>

                <SwiperSlide>
                <img src="/images/icons/partner-1.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-2.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-3.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-4.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-5.png" alt="" />
                </SwiperSlide>
                
                <SwiperSlide>
                <img src="/images/icons/partner-6.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-7.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/partner-8.png" alt="" />
                </SwiperSlide>
                
                  
            </Swiper>
        </div>
        <div className='py-2'></div>
        </div>
    </div>
  )
}

export default Sponsor
import React,{useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showAlertMessage } from '../components/MasterComponents/CommonFunctionalities'
import PlainHero from '../components/PlainHero/PlainHero'
import PaymentCheckoutForm from '../components/PaymentCheckoutForm/PaymentCheckoutForm'



function PaymetForm() {
    const userLogin = useSelector((state)=> state.userLogin)
    const cartInfo = useSelector((state)=> state.cartItems)
    const [btnEnable, setBtnEnable] = useState(false)


    useEffect(()=>{
        const checkAligibility = ()=>{
          if(!cartInfo?.length > 0 && !userLogin?.userInfo?.token)
            {
              showAlertMessage("No item added to cart yet","fail")
              return
            }   
            setBtnEnable(true)   
        }
    
        checkAligibility()
      },[])

  return (
    <>
        <PlainHero
         heading="Checkout"
         subHeading=""
         content=""
         bankLogo={true}
        />
        <PaymentCheckoutForm btnEnable={btnEnable}/>

    </>
  )
}

export default PaymetForm
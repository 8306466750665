import React from 'react';
import Aboutus from '../../components/AboutUs/aboutUs'; 
import HomeHero from '../../components/HomeHero/HomeHero'; 
import StartToSuccessComponent from '../../components/StartToSuccess/StartToSuccessComponent ';
import CourseCard from '../../components/CourseCard/CourseCard';
import ArticlesSlider from '../../components/ArticlesSlider/ArticlesSlider';
import TestimonialForm from '../../components/TestimonialForm/TestimonialForm';
import NewModal from '../../components/NewModal/NewModal';  



const HomePage = () => {
  return (
    <div> 
      <HomeHero/>
      {/* <NewModal />   */} 
      <Aboutus />
      <div style={{backgroundImage:"url('/images/creatives/home-composite-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
      <CourseCard/>
      <StartToSuccessComponent />  
      {/* <ArticlesSlider/>  */}
      <TestimonialForm/>
      </div>
    </div>
  )
}

export default HomePage;
import React, { useState } from "react";
import "./HeaderSliderWraper.css";
import AdminHeader from "../AdminHeader/AdminHeader";
import SideSlider from "../SideSlider/SideSlider";

function HeaderSliderWraper({ changeScreen, children ,selctedTabNumber,sideOptions}) {
  const [hideSideBar, setHideSideBar] = useState(window.innerWidth> 993? false : true);

  return (
    <div className="HeaderSliderWraper-container ">
      <AdminHeader setToggleSidebar={setHideSideBar} hideState={hideSideBar}/>

      <div className="container-fluid">
        <div className="row ">
          <div className={`${hideSideBar ? "d-none" : "col-lg-3"}`}>
            <SideSlider changeScreenTab={changeScreen} selctedTabNumber={selctedTabNumber} setToggleSidebar={setHideSideBar} sideOptions={sideOptions} />
          </div>
          <div className={`${hideSideBar ? "col-lg-12 m-0 p-0" : "col-lg-9 m-0 p-0"}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderSliderWraper;

import React,{useState,useEffect} from 'react';
import PastPapers from '../../components/PastPapersSection/PastPapers';
import PlainHero from '../../components/PlainHero/PlainHero';
import ContactForm from "../../components/TestimonialForm/Contact Form/Contactform"
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from "../../components/CommonAPICalls/Settings";
import Loader from "../../components/UI Helpers/Simple Loading/Loader";

const papers1 = [
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];
const papers2 = [
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];
const papers3 = [
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "O Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];

const PastPaperPage = () => {
    const [pastPapers,setPastPapers] = useState([])
    const [loading, setLoading] = useState(true)
    const [error,setError] = useState(false)

    const apiCall = new CommonCalls();
    const getPastPapersByTypes = async()=>{
            setLoading(true)
            const response = await apiCall.getTropicalPastPapersByType(Settings.enumTypes[0]?.value);
            if(response)
            {
                setError(false)
                setLoading(false)
                return response
            }
            else
            {
                setLoading(false)
                setError("Could not load data from server")
                return 
            }
    }

    useEffect(()=>{
        const getApiData = async()=>{
            const papersResponse = await getPastPapersByTypes()
            if(!error)
            {
                setPastPapers(papersResponse)
            }
        }

        getApiData()
    },[])

  return (
    <div> 
        {/* <NavbarCS /> */}
        <PlainHero
          heading="Topical Past Papers"
          subHeading=""
          content="Our topical past papers are well organized to ensure effective practice. Each question includes a clear reference to the year it was taken from. Students can easily track their progress and focus on their weak areas by practicing questions from specific chapters. "
        />
        <div style={{backgroundImage:"url('/images/creatives/tropical-composite-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
      
      {
          loading ?
            <Loader/>
          :
          pastPapers &&
            <PastPapers  pastPapersContent={pastPapers} 
            title="O Level 5070 AND IGCSE 0620 Topical Past Papers" 
            content={"Our O Level past paper course offers comprehensive sessions covering O levels Paper 1 MCQs, Paper 2 Theory and Paper 4 Alternative to Practical and IGCSE Paper 2 MCQs, P4 Theory and P6 Alternative to Practical. Students can enroll to gain thorough practice, understand various question patterns and learn about effective strategies, boosting their preparation. "}
            bgImg={"bg1"}/>
      }
        <ContactForm/>
        </div>
    </div>
  )
}

export default PastPaperPage
import React,{useState} from "react";
import "./CoursesHero.css";
import Settings from "../CommonAPICalls/Settings";

  const catagory = [
    "O-Level",
    "AS-Level",
    "A2-Level"
  ]

function CourseHero({selectedCourseType}) {
    const [selectedType,setSelectedType] = useState(1)

  return (
    <div className='CourseHero-container py-5 '>
        <div className="container my-1">
        <div className="blog-upper-content  mb-2">
          <div className="row">
            <div className="col-12">
              <div className="blog-text-content text-center">
                
                <h3 className="h1_main">My Courses</h3>   
               
              </div>
            </div>
            
          </div>

          <div className="row btn-container mx-auto ">
            {
                Settings.enumTypes.map((types)=>{
                    return( 
                        <div className="col-lg-4 ">
                            <button className={`w-100 gen-btn ${selectedType == types.value ? "gen-btn-active" : ""}`}
                                onClick={()=>{setSelectedType(types.value);selectedCourseType(types.value)}}
                        >
                                {types.label}
                            </button>
                        </div>
                    )
                })
            }
           
          </div>
      </div>

        
    </div>
 </div>

  )
}

export default CourseHero;
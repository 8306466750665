
export const addToCart = (item,purchaseDetails) => ({
  type: "ADD_TO_CART",
  payload: item,
  purchaseDetails : purchaseDetails
});

export const removeFromCart = (itemId) => ({
  type: "REMOVE_FROM_CART",
  payload: itemId,
});

export const clearCart = () => ({
  type: "CLEAR_CART",
});

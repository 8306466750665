import React from 'react';
import './LatestNews.css';
import { useNavigate } from 'react-router-dom';

const LatestNews = () => {
  const navigate = useNavigate()
  return (
    <div className="container latest-container">
    <div className="row">
      <div className="col-lg-6">
        <img src="/images/blog-banner.png" alt="Haircut" className="img-responsive" />
      </div>
      <div className="col-lg-6 content-div">
        <h4 className='h2_main'>Latest News</h4>
        <h2 className='h1_main'>How to design a simple, yet unique and memorable brand</h2>
        <p className='para_main'>Convallis vivamus at cras porta nibh velit aliquam Convallis vivamus at cras porta nibh velit aliquam</p>
        <a onClick={()=>{navigate("/newsinner")}} class="btn btn-link">Read More<img src="images/Icon-akar-arrow-left.svg" className='arrow-img' alt="Arrow Icon" />
</a>

      </div>
    </div>
  </div>
  )
}

export default LatestNews
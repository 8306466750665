import { useNavigate } from "react-router-dom"

function CardBox(props) {
  const navigate = useNavigate()
    return (
      <div className="card-box">
          <img src={props.cardImg} alt="someImage" />
          <div className="card-text-content">
            <div className="top-links">
              <div className="blue-box">
                <p className="m-0">{props.typeName}</p>
              </div>
              <div className="d-flex align-items-center justify-content-start gap-1">
                <img src="/images/icons/red-star-icon.svg" className="m-0"/>
                <p className="m-0">5.0 Ratings</p>
              </div>
            </div>
            <h5 className="title mb-0">{props.title}</h5>
            <p className="mb-2 red-tex">{props.content}</p>

            <div className="d-flex justify-content-lg-between justify-content-start align-items-lg-center align-items-start mb-3">
                <h5 className="mb-0 price-text">PKR {props.price} /-</h5>
                <button 
                  className="read-more-anchor"
                  onClick={()=>{navigate(`/courses/course-preview/${props?.slug_url}`)}}
                >Get Enrolled</button>
            </div>
          </div>
          
      </div>
    )
  }
  
  export default CardBox
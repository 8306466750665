import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './CardGrid.css';
import { useNavigate } from 'react-router-dom';

function CardGrid() {
  const navigate = useNavigate()
  const cards = [
    {
      title: "How to design a simple, yet unique and memorable",
      text: "Latest News",
      image: "/images/blog-banner.png",
      link: "/newsinner"
    },
    {
      title: "How to design a simple, yet unique and memorable",
      text: "Latest News",
      image: "/images/blog-banner.png",
      link: "/newsinner"
    },
    {
      title: "How to design a simple, yet unique and memorable",
      text: "Latest News",
      image: "/images/blog-banner.png",
      link: "/newsinner"
    },
    {
      title: "How to design a simple, yet unique and memorable",
      text: "Latest News",
      image: "/images/blog-banner.png",
      link: "/newsinner"
    },
    {
      title: "How to design a simple, yet unique and memorable",
      text: "Latest News",
      image: "/images/blog-banner.png",
      link: "/newsinner"
    },
    {
      title: "How to design a simple, yet unique and memorable",
      text: "Latest News",
      image: "/images/blog-banner.png",
      link: "/newsinner"
    },
  ];

  return (
    <Container className='main-cardgrid'>
      <Row>
        {cards.map((card, index) => (
          <Col sm={12} md={6} lg={4} key={index} className="mb-5">
            <Card style={{ border: 'none', padding: '0px 12px 0px 12px'}}>
              <Card.Img variant="top" src={card.image} />
              <Card.Body className='card-body'>
                <Card.Subtitle className="mb-2 text-muted">{card.text}</Card.Subtitle>
                <Card.Title className='card-title'>{card.title}</Card.Title>
                <Card.Link className='href' onClick={()=>{navigate(card.link)}}>Read More <img className='arrow-img' src='images/Icon-akar-arrow-left.svg' /></Card.Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default CardGrid;

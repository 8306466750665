import React,{useState} from "react";
import './ChemistryATPSession.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";

const cardDataOlevel = [
    {
      cardImg: "./images/creatives/chemistryATPBanner.png",
      title: "O LEVEL CHEMISTRY ATP SESSION",
      price:"PKR 1,500",
      content:"Moosa Khan",
    },
    {
      cardImg: "./images/creatives/chemistryATPBanner.png",
      title: "O LEVEL CHEMISTRY ATP SESSION",
      price:"PKR 1,500",
      content:"Moosa Khan",
    },
    {
      cardImg: "./images/creatives/chemistryATPBanner.png",
      title: "O LEVEL CHEMISTRY ATP SESSION",
      price:"PKR 1,500",
      content:"Moosa Khan",
    }
    
  ];

//   const catagory = [
//     "O - Level",
//     "AS-Level",
//     "A2-Level"
//   ]

function ChemistryATPSession() {
    const [cat,setCat] = useState("O - Level")

  return (
    <div className='CourseCard-container py-2 '>
        <div className="container">
        <h1 className="h1_main">Enrolled Courses</h1>
        {/* <div className="blog-upper-content  mb-5">
          <div className="row">
            <div className="col-12">
              <div className="blog-text-content text-center">
                
                <h3 className="h1_main">O-Levels | A2-Levels | AS-Levels</h3>   
                <h5 className="h1_main">Chemistry Courses</h5>
                
              </div>
            </div>
            
          </div>

          <div className="row btn-container mx-auto ">
            {
                catagory.map((ele)=>{
                    return( 
                        <div className="col-lg-4 ">
                            <button className={`gen-btn ${cat == ele ? "gen-btn-active" : ""}`}
                                onClick={()=>{setCat(ele)}}
                        >
                                {ele}
                            </button>
                        </div>
                    )
                })
            }
           
            

          </div>
      </div> */}

      <Swiper
      loop={true}
      spaceBetween={50}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
        
      }}
      breakpoints={{
        280: {
          slidesPerView: 1,
        },
        992: {
          slidesPerView: 3,
        }
      }}
      className="card-list mx-0 w-100 px-2"
    >
        
      {cardDataOlevel.map((ele, ind) => (
        <SwiperSlide key={ind} style={{ height: "auto" }}>
    
          <CardBox
            className="h-100"
            cardImg={ele.cardImg}
            title={ele.title}
            price={ele.price}
            content={ele.content}
          />
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
 </div>

  )
}

export default ChemistryATPSession;
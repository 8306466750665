import React, { useState, useEffect, useRef } from 'react';
import "./TextCounter.css"

function TextCounter({ end, repeatScroll = false, speed, title, PostText = "+", startOnView = false }) {
    const [count, setCount] = useState(0);
    const [inView, setInView] = useState(!startOnView);
    const counterRef = useRef(null);

    useEffect(() => {
        let observer;
        if (startOnView) {
            observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setInView(true);
                        observer.disconnect();
                    }
                },
                {
                    threshold: 0.1 
                }
            );
            if (counterRef.current) {
                observer.observe(counterRef.current);
            }
        }

        return () => observer && observer.disconnect();
    }, [startOnView]);

    useEffect(() => {
        let timer;
        if (inView) {
            timer = setInterval(() => {
                if (count < end) {
                    setCount(prevCount => prevCount + 1);
                } else if (repeatScroll) {
                    setCount(0);
                }
            }, speed);
        }

        return () => clearInterval(timer);
    }, [count, end, repeatScroll, inView, speed]);

    return (
        <div className='TextCounter-container' ref={counterRef}>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <h3 className='h1_main mb-3' style={{ fontWeight: "700" }}>{count}{PostText}</h3>
                <h4 className='h3_main text-center'>{title}</h4>
            </div>
        </div>
    );
}

export default TextCounter;

import { useNavigate } from "react-router-dom"

function CardBox(props) {
  const navigate = useNavigate()
    return (
      <div className="card-box">
          <img src={props.cardImg} alt="someImage" />
          <div className="card-text-content text-center">
            <p className="red-text m-0 p-0">{props?.title}</p>
            <h5 className="title m-0 p-0">{props?.content}</h5>

            <div className="d-flex justify-content-lg-between justify-content-start align-items-lg-center align-items-start mb-3 buttons-area">
               
                <a  style={{textDecoration:'none'}} className="mx-auto" onClick={()=>{window.open(`${props?.driveLink}`)}}><button className="connect-button w-100" >Preview</button></a>
            </div>
          </div>
      </div>
    )
  }
  
  export default CardBox
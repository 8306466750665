import React, { useState } from 'react';
import './OLevelChemistryATPSession.css';


const OLevelChemistryATPSession = ({goBack}) => {
    const [showPreview, setShowPreview] = useState(false)


    const closeModal = () => setShowPreview(false);

    const lectures = [
        {
            lectureNumber: 1,
            title: 'Convallis vivamus at cras porta nibh velit aliquam',
            duration: '52:54',
            drivebutton: 'Drive Link',
        },
        {
            lectureNumber: 2,
            title: 'Sed felis eget velit aliquet sagittis id consectetur',
            duration: '52:54',
            drivebutton: 'Drive Link',
        },
        {
            lectureNumber: 3,
            title: 'Morbi tristique senectus et netus et malesuada',
            duration: '52:54',
            drivebutton: 'Drive Link',
        },
        {
            lectureNumber: 4,
            title: 'Ut tristique et egestas quis ipsum suspendisse',
            duration: '52:54',
            drivebutton: 'Drive Link',
        },
        {
            lectureNumber: 5,
            title: 'Vestibulum sed arcu non odio euismod lacinia',
            duration: '52:54',
            drivebutton: 'Drive Link',
        }
    ];


    return (
        <div className="container-fluid mt-5 curriculam-main">
            <div className="row">

                <div className="col-lg-6">
                    <div className="card">
                        <img className="card-img-top" src="/images/curriculam.png" alt="Course" />
                        <div className="card-body">
                            <h5 className="card-title">PKR 1500/-</h5>
                            <div className='d-flex Box-main'>
                                <img src='/images/Group-2037.svg'></img>
                                <h4 className='box-text'>Lectures</h4>
                                <h5 className='span-text'>5 Lessons</h5>

                            </div>
                            <hr className='divider'></hr>
                            <div className='d-flex Box-main'>
                                <img src='/images/Group-2038.svg'></img>
                                <h4 className='box-text'>Level  </h4>
                                <h5 className='span-text'>Intermediate</h5>

                            </div>
                            <hr className='divider'></hr>
                            <div className='d-flex Box-main'>
                                <img src='/images/Group-2042.svg'></img>
                                <h4 className='box-text'>Topic </h4>
                                <h5 className='span-text'>Kinetics</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 curriculam-start">
                    <div className='d-flex align-items-center mb-3'>
                    </div>
                    <div className="list-group">
                        {lectures.map((lecture, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className='lecture-class'>Lectures {lecture.lectureNumber}</h5>
                                </div>
                                <div className="text-right d-flex">
                                    <button className="drive-btn w-100" style={{ marginTop: "0px", marginRight: "22px" }}>{lecture.drivebutton}</button>
                                    <p className='duration align-items-center justify-content-center'>{lecture.duration}</p>
                                </div>
                            </li>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OLevelChemistryATPSession;

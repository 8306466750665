import React from 'react'
import "./PlainHero.css"
import NavbarCS from '../Header/Navbar';

function PlainHero({
    heading="",
    subHeading="",
    content="",
    enrolledText="",
    bankLogo=false
}) {
  return (
<>
        {/* <NavbarCS/> */}
    <div className='PlainHero-container pb-5'>
        <div className='container main-container'>

        <div className="text-content text-center">
            {enrolledText &&
            <div className='d-flex justify-content-center align-items-center gap-3'>
                <img src="/icons/user-icon.jpeg" alt='icon'/>
                <p className="para_main para-content enrolled-text m-0">{enrolledText}</p>

                </div>}
            <h3 className='h2_main'>{subHeading}</h3>
            <h1 className="h1_main my-3">
                {heading}
            </h1>

            <p className="para_main para-content">{content}</p>

            {
                bankLogo &&
                <img
                    src='/images/icons/meezan-bank-logo.png'
                    alt='bank-logo'
                    className='m-auto'
                />
            }
            
        </div>
        

        </div>
    </div>
    </>
  )
}

export default PlainHero
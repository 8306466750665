import React from "react";
import "./TestMarksPopup.css";
import { useNavigate } from "react-router-dom";

const TestMarksPopup = ({ closeModal }) => {
  const navigate = useNavigate();
  return (
    <div className="container testmark-container">
      <div className="row no-gutters">
        <div className="col-12">
          <div className="img-modal d-lg-none text-end">
            <img
              onClick={closeModal}
              src="/icons/TestMark-cross-icon.png"
              alt="cross-icon"
              className="icon-cross"
            />
          </div>
        </div>
        <div className="col-12">
          <div className="test-marks-popup-box">
            <h2 className="h1_main">Test Marks</h2>
            <form>
              <div className="form-group row">
                <div className="col-md-6">
                  <div className="select-wrapper">
                    <select className="placeholder-form-control">
                      <option value="" disabled selected>
                        Select a subject
                      </option>
                      <option value="Organic Chemistry - A Levels">
                        Organic Chemistry - A Levels
                      </option>
                      <option value="Physics - A Levels">
                        Physics - A Levels
                      </option>
                      <option value="Mathematics - A Levels">
                        Mathematics - A Levels
                      </option>
                      <option value="Biology - A Levels">
                        Biology - A Levels
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Date Of Topic"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Marks"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Marks Out Of"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Attendance"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Out Of Attendance"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestMarksPopup; 
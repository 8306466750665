import React,{useState,useEffect} from 'react'
import PlainHero from '../../components/PlainHero/PlainHero';
import OLevelNotes from '../../components/OLevelNotesPage/OLevelNotes';
import ContactForm from "../../components/TestimonialForm/Contact Form/Contactform"
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from '../../components/CommonAPICalls/Settings';
import Loader from '../../components/UI Helpers/Simple Loading/Loader';
import { showAlertMessage } from '../../components/MasterComponents/CommonFunctionalities';


const AsLevelNotesPage = () => {
  const [notes,setnotes] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error,setError] = useState(false)

  const apiCall = new CommonCalls();
  const getNotesByTypes = async()=>{
          setLoading(true)
          const response = await apiCall.getNotesByType(Settings.enumTypes[1]?.value);
          if(response?.length > 0)
          {
              setError(false)
              setLoading(false)
              return response
          }
          else
          {
              setLoading(false)
              setError("Could not load data from server")
              showAlertMessage("Could not load data from server","fail")
              return 
          }
  }

  useEffect(()=>{
      const getApiData = async()=>{
          const notesResponse = await getNotesByTypes()
          if(!error)
          {
              setnotes(notesResponse)
          }
      }

      getApiData()
  },[])
  return (
    <div>
         <PlainHero
          heading="AS Level Notes"
          content="Check out our organized AS level notes, covering Physical Chemistry, Inorganic Chemistry, Organic Chemistry and Analytical Chemistry. Each section is separated to provide ease and clarity, ensuring effective learning and preparation."
        />
        <div style={{backgroundImage:"url('/images/creatives/olevel-composite-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
          {
          loading ?
            <Loader/>
          :
          notes &&
            <OLevelNotes 
              notesData={notes}
            />
          }
          <ContactForm/> 
        </div>
    </div>
  )
}

export default AsLevelNotesPage;
import React from 'react'
import NewsAndEvents from '../../components/LMS Portal/NewsAndEvents/NewsAndEvents';
import HomeHero from '../../components/HomeHero/HomeHero'; 
import ChemistryATPSession from '../../components/LMS Portal/Enrolled Courses/ChemistryATPSession/ChemistryATPSession';
import ChemistryATPSessionPartTwo from '../../components/LMS Portal/Enrolled Courses/ChemistryATPSessionPartTwo/ChemistryATPSessionPartTwo';
import OLevelChemistryATPSession from '../../components/LMS Portal/Enrolled Courses/OLevelChemistryATPSession/OLevelChemistryATPSession';

const NewsAndEventsPage = () => {
  return (
    <div>
        <HomeHero/>
        <NewsAndEvents />
        <ChemistryATPSession />
        <ChemistryATPSessionPartTwo />
        <OLevelChemistryATPSession />
    </div>
  )
}

export default NewsAndEventsPage
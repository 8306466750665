import React,{useState,useEffect} from 'react';
import PastPapers from '../../components/PastPapersSection/PastPapers';
import PlainHero from '../../components/PlainHero/PlainHero';
import ContactForm from "../../components/TestimonialForm/Contact Form/Contactform"
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from "../../components/CommonAPICalls/Settings";
import Loader from "../../components/UI Helpers/Simple Loading/Loader";

const papers1 = [
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];
const papers2 = [
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];
const papers3 = [
  { year: 2024, subject: "A2Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#A8B5EB", titleColor: "#A8B5EB" },
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#E17AA1", titleColor: "#E17AA1" },
  { year: 2024, subject: "A2 Level Chemistry ATP", paragraph: "Convallis vivamus at cras convallis", price: "PKR 6000/-", color: "#0EC2A8", titleColor: "#0EC2A8" }
];

const PastPaperPageALevel = () => {
    const [pastPapers,setPastPapers] = useState([])
    const [loading, setLoading] = useState(true)
    const [error,setError] = useState(false)

    const apiCall = new CommonCalls();
    const getPastPapersByTypes = async()=>{
            setLoading(true)
            const response = await apiCall.getTropicalPastPapersByType(Settings.enumTypes[2]?.value);
            if(response)
            {
                setError(false)
                setLoading(false)
                return response
            }
            else
            {
                setLoading(false)
                setError("Could not load data from server")
                return 
            }
    }

    useEffect(()=>{
        const getApiData = async()=>{
            const papersResponse = await getPastPapersByTypes()
            if(!error)
            {
                setPastPapers(papersResponse)
            }
        }

        getApiData()
    },[])
  
  return (
    <div>
      {/* <NavbarCS /> */}
      <PlainHero
        heading="Topical Past Papers"
        subHeading=""
        content="Our topical past papers are well organized to ensure effective practice. Each question includes a clear reference to the year it was taken from. Students can easily track their progress and focus on their weak areas by practicing questions from specific chapters. "
      />
      <div
        style={{
          backgroundImage: "url('/images/creatives/tropical-composite-bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          pastPapers && (
            <PastPapers
              pastPapersContent={pastPapers}
              bgImg={"bg1"}
              title="A2 level Topical Past Papers"
              content={"A2 level is certainly not a piece of cake which you can master just by scramming notes. It requires way more effort and dedication than AS level. Our A2 Level Topical Past Papers booklets are specifically designed to overcome these challenges. By enrolling, you'll gain access to an organized collection of past paper questions, each labeled with its respective year."}

            />
          )
        )}
        <ContactForm />
      </div>
    </div>
  );
}

export default PastPaperPageALevel
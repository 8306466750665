import "./Testimonials.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay,Pagination } from "swiper/modules";
import TestimonialCard from "./TestimonialCard";
import 'swiper/css/pagination';

function Testimonials() {
  
  const testimonialData = [
    {
      clientName: "Faryal Malik",
      clientImg: "/images/icons/testimonial-female.png",
      clientReview:
        "I was struggling with O level chemistry at school and became very worried. Then I realized that I needed extra help so I decided to go for private coaching. In just 3-4 months of studying with you, my understanding of chemistry has improved. You're more than a teacher to me now. Your ability to clarify concepts has left me speechless. I highly recommend your classes to any junior looking for a chemistry tutor.",
    },
    {
      clientName: "Emaan",
      clientImg: "/images/icons/testimonial-female.png",
      clientReview:
        "I have been Sir Moosa's student since O levels and have now studied AS level with him as well. His classes were amazing! He ensured we fully understood each concept before moving on and solved several past paper questions with us. Sir Moosa is a really dedicated teacher. I highly recommend him and would give him a 10/10.",
    },
    {
      clientName: "Mutaf ur Rehman",
      clientImg: "/images/icons/testimonial-male.png",
      clientReview:
        "Sir Moosa gives plenty of examples whilst teaching difficult topics. He doesn't just teach like a teacher; he treats us as his friends leading to a friendly class atmosphere. This approach makes everyone comfortable and we can discuss our queries without any hesitation. ",
    },
    {
      clientName: "Ismail",
      clientImg: "/images/icons/testimonial-male.png",
      clientReview:
        "Hands down the best chemistry teacher I've ever come across. His teaching methods are outstanding and he is very helpful indeed. Even though I joined the session late, I never felt like I missed anything because he was always there to clear up any sort of confusion. I once asked him a question at midnight and within 10 minutes, my doubts were resolved. Highly recommended!!",
    },
    {
      clientName: "Meerab",
      clientImg: "/images/icons/testimonial-female.png",
      clientReview:
        "I had an incredibly positive experience in Sir Moosa's chemistry class. It's clear that Sir Moosa is deeply passionate about the subject and dedicated to his students' success. He was always approachable and willing to help outside the class, whether through WhatsApp or extra sessions. His supportive and encouraging behavior made me feel comfortable while questioning and asking for further clarification when needed. Thanks to him, I feel well prepared for future studies in this subject.",
    },
  ];

  
  return (
    <div className="Testimonials-container py-5">
      <div className="container">
    <div className="row d-flex align-items-center">
      <div className="col-md-12 text-center">
        <p className="sub-heading h2_main mb-2">My Testimonials</p>
        <h3 className="text-center mb-4 h1_main pb-3">What Do Students Say About Me?</h3>
        <p className="body-paragraph">Hear from students who have experienced the journey of learning chemistry with me.
        </p>
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 8000,disableOnInteraction:false }}
            loop={true}
            modules={[Autoplay,Pagination]}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="testimonials-list "
            id="articles-cards-slider"
            >
              {
                testimonialData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview} />
                    </SwiperSlide>
                     </>)
                })
              }
        </Swiper>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials
import React from 'react';
import "./footerHero.css";
import { Link, useNavigate } from "react-router-dom";

const FooterHero = () => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    return (
        <footer className="footer">
            <div className='container'>
                <div className='upper-section'>
                    <div className="footer-section">
                        <img src="/images/mk-logo-Black-landscape.png" alt="Website Logo" className="logo" />
                        <p className="footer-text">Moosa Khan is well known for inspiring and educating thousands of chemistry students. With a passion for teaching, he ensures that his classes are engaging and comprehensive. </p>

                        <div className="social-icons">
                            <a rel="noopener" target='blank' href='https://www.facebook.com/chemwithmk' >
                                <img src="/images/Facebook-icon.svg" alt="Facebook" className="logo-left" />
                            </a>

                            <a rel="noopener" target='blank' href='https://www.instagram.com/chemwithmk' >
                                <img src="/images/Instagram-icon.svg" alt="Instagram" className="logo-left" />
                            </a>

                            <a rel="noopener" target='blank' href='https://youtube.com/@chemwithmk' >
                                <img src="/images/youtube-icon.svg" alt="youtube" className="logo-left" />
                            </a>

                            
                            {/* <a rel="noopener" target='blank' href='' >
                                <img src="/images/Whatsapp-icon.svg" alt="WhatsApp" className="logo-left" />
                            </a> */}
                        </div>
                    </div>

                    <div className="footer-sect">
                        <ul className='ul-segment'>
                            <h2 className='head-h1'>QuickLinks</h2>
                            <li onClick={() => handleClick("/")}><span className='span-hover'>Home</span></li>
                            <li onClick={() => handleClick("/about")}><span className='span-hover'>About</span></li>
                            <li onClick={() => handleClick("/courses")}><span className='span-hover'>Courses</span></li>
                            <li onClick={() => handleClick("/contact")}><span className='span-hover'>Contact</span></li>
                        </ul>
                    </div>
                    <div className="footer-second-section">
                        <ul className='ul-segment-second'>
                            <h2 className='head-h1'>Terms & Updates</h2>
                            <li onClick={() => handleClick("/contact")}><span className='span-hover'>Refund Policy</span></li>
                            <li onClick={() => handleClick("/contact")}><span className='span-hover'>Privacy Policy</span></li>
                        </ul>
                    </div>

                    <div className="footer-section-right">
                        <a href='mailto:chem.moosakhan@gmail.com' style={{textDecoration:"none"}}>
                        <p className='specific-paragraph'>
                            <img src="/images/gmail-icon.svg" alt="Envelope" className="contact-icon" /> chem.moosakhan@gmail.com
                        </p>
                        </a>
                        <a href='tel:+923452494359' style={{textDecoration:"none"}}>
                        <p className='specific-paragraph'>
                            <img src="/images/phone-icon.svg" alt="Phone" className="contact-icon" /> +923452494359
                        </p>
                        </a>
                        <p className='specific-paragraph'>
                            <img src="/images/location-icon.svg" alt="Map Marker" className="contact-icon" /> A 480 Block 5 Near Sir Syed University Gulshan e Iqbal, Karachi
                        </p>
                    </div>
                </div>

                <div className="blog-subfooter">
                    <hr className="divider" />
                    <div className="footer-content">
                        <p className="p-text">&copy; 2024 Chemistry with Moosa Khan  |  All rights reserved</p>
                        <div className="social-icons-bottom">
                            {/* <img src="/images/Icon-awesome-cc-visa.svg" alt="Visa" className="icons" />
                            <img src="/images/Icon-awesome-stripe.svg" alt="Stripe" className="icons" />
                            <img src="/images/Icon-awesome-amazon-pay.svg" alt="Amazon Pay" className="icons" />
                            <img src="/images/Icon-awesome-cc-mastercard.svg" alt="Mastercard" className="icons" />
                            <img src="/images/Icon-awesome-paypal.svg" alt="PayPal" className="icons" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterHero;

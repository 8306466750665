import CryptoJS from "crypto-js";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";

export const encryptPassword = (password) => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
  const derived_key = CryptoJS.enc.Base64.parse(secretKey);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);
  const encryptionOptions = {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  };
  const encryptedPassword = CryptoJS.AES.encrypt(
    password,
    derived_key,
    encryptionOptions
  ).toString();
 
  return encryptedPassword;
};

export const validateForm = (inputsFieldAr, formInputData) => {
  let passStatus = true;
  inputsFieldAr.map((input) => {
    if (!formInputData[input.name] && passStatus && input.required) {
      showAlertMessage(`${input.label} field required`, "fail");
      passStatus = false;
    }
  });

  return passStatus;
};

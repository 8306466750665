import React,{useState,useEffect} from 'react'
import PlainHero from '../../components/PlainHero/PlainHero';
import OLevelNotes from '../../components/OLevelNotesPage/OLevelNotes';
import ContactForm from "../../components/TestimonialForm/Contact Form/Contactform"
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import Settings from '../../components/CommonAPICalls/Settings';
import Loader from '../../components/UI Helpers/Simple Loading/Loader';
import { showAlertMessage } from '../../components/MasterComponents/CommonFunctionalities';

const ALevelNotesPage = () => {
  const [notes,setnotes] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error,setError] = useState(false)

  const apiCall = new CommonCalls();
  const getNotesByTypes = async()=>{
          setLoading(true)
          const response = await apiCall.getNotesByType(Settings.enumTypes[2]?.value);
          if(response?.length > 0)
          {
              setError(false)
              setLoading(false)
              return response
          }
          else
          {
              setLoading(false)
              setError("Could not load data from server")
              showAlertMessage("Could not load data from server","fail")
              return 
          }
  }

  useEffect(()=>{
      const getApiData = async()=>{
          const notesResponse = await getNotesByTypes()
          if(!error)
          {
              setnotes(notesResponse)
          }
      }

      getApiData()
  },[])
  return (
    <div>
         <PlainHero
          heading="A Level Notes"
          content="Our A2 level notes are divided into sections, with each section further broken down into chapters. This structured approach helps students go through the material easily, making studying and revision more efficient and effective."
        />
        <div style={{backgroundImage:"url('/images/creatives/olevel-composite-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
          {
            loading ?
              <Loader/>
            :
            notes &&
              <OLevelNotes 
                notesData={notes}
              />
          }
          <ContactForm/> 
        </div>
    </div>
  )
}

export default ALevelNotesPage;
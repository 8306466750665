import React, { useState } from 'react';
import './CommonComponentsStylings.css';

const CheckboxGroup = ({ id, options, onChange, name, label, required }) => {
  const [checkedState, setCheckedState] = useState(
    options.map((option) => ({
      label: option.label,
      isChecked: option.isChecked || false,
      inputValue: '',
      isOther: option.isOther ?? false
    }))
  );

  const handleCheckboxChange = (index, event) => {
    const newCheckedState = checkedState.map((item, idx) =>
      idx === index ? { ...item, isChecked: !item.isChecked } : item
    );

    setCheckedState(newCheckedState);

    // Gather selected values
    const selectedValues = newCheckedState
      .filter(option => option.isChecked)
      .map(option => (option.isOther ? option.inputValue : option.label));

    if (onChange) {
      onChange(event, selectedValues, name);
    }
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    const newCheckedState = checkedState.map((item, idx) =>
      idx === index
        ? { ...item, inputValue: value }
        : item
    );

    setCheckedState(newCheckedState);

    // Gather selected values
    const selectedValues = newCheckedState
      .filter(option => option.isChecked)
      .map(option => (option.isOther ? option.inputValue : option.label));

    if (onChange) {
      onChange(event, selectedValues, name);
    }
  };

  return (
    <div className="checkbox-group d-flex flex-column align-items-start gap-2">
      <label htmlFor={id} style={{ fontWeight: "500" }}>
        {label}{required && <span style={{ color: 'red' }}>*</span>}
      </label>
      {options.map((option, index) => (
        <div key={index} className="checkbox-wrapper d-flex align-items-center ps-2">
          <input
            type="checkbox"
            id={`checkbox-${index}`}
            disabled={option.disabled}
            checked={checkedState[index].isChecked}
            onChange={(event) => handleCheckboxChange(index, event)}
            style={{ width: "20px", height: "20px" }}
          />
          <label htmlFor={`checkbox-${index}`}>
            {option.label}
          </label>

          {option.isOther && checkedState[index].isChecked && (
            <input
              type="text"
              value={checkedState[index].inputValue}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="Please specify..."
              className="other-text-input ms-2"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;

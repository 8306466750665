import React from 'react';
import BlogInner from '../../components/BlogInner/BlogInner';
import ArticlesSlider from '../../components/ArticlesSlider/ArticlesSlider';
import Navbar from '../../components/Header/Navbar';

const BlogPage = () => {
  return (
    <div>
      <BlogInner />
      {/* <ArticlesSlider/>  */}
    </div>
  )
}

export default BlogPage
import React, { useEffect, useState } from 'react'
import Modal from '../../Model/Modal';
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import FieldsRenderer from '../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent';
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities';
import Loader from '../../UI Helpers/Simple Loading/Loader';

const DetailViewLessonPopup = ({ setPopupState, openPopup, reloadParentGrid, lesson_id=null, course_id=null ,freeCourse=false}) => {
  const [saveBtn, setSaveBtn] = useState(false);
  const [lessonDetails, setLessonDetails] = useState({});

  const apiCalls = new CommonCalls();

  useEffect(() => { getSystemCourseLessonsById() }, []);

  const getSystemCourseLessonsById = async () => {
    if(!lesson_id) {
      setSaveBtn(false);
      return;
    };

    setSaveBtn(true);
    const lessonResponse = await apiCalls.getCourseLessonById(lesson_id);
    if (lessonResponse?.status === 200) {
      if(freeCourse)
      {
        if (lessonResponse && lessonResponse.data) {
          lessonResponse.data.free_lecture_view = freeCourse;
        }
      }
      setLessonDetails(lessonResponse?.data); 
      setSaveBtn(false);
    };
    setSaveBtn(false);
  };
  
  const createNewCourseLesson = async () => { 
    const lessonItem = await apiCalls.addNewCourseLesson({...lessonDetails, course_id});
    
    if(lessonItem?.status == 201){
      showAlertMessage(lessonItem?.message, 'success');
      reloadParentGrid();
      setSaveBtn(false);
      setPopupState(false)
    } else {
      showAlertMessage('Course Lesson couldnt be created!', 'fail');
      setSaveBtn(false);
    }
  };

  const updateExistingCourseLesson = async () => { 
    const updatelessonResponse = await apiCalls.updateCourseLesson(lesson_id, lessonDetails);

    if(updatelessonResponse?.status == 202){
      showAlertMessage(updatelessonResponse?.message, 'success');
      reloadParentGrid();
      setSaveBtn(false);
    } else {
      showAlertMessage('Course Lesson couldnt be updated!', 'fail');
      setSaveBtn(false);
    }
  };

  const inputFieldsLessonsArray = [
    { label: "Lesson Title", className: "col-lg-4", inputType: "text", name: "lesson_title", id: "lesson_title", required:true },
    { label: "Lesson Description", className: "col-lg-4", inputType: "text", name: "lesson_description", id: "lesson_description", required:true },
    { label: "Lesson Link", className: "col-lg-4", inputType: "text", name: "lesson_link", id: "lesson_link", required:true }, 
    { label: "Lesson Duration (mintues)", className: "col-lg-4", inputType: "number", name: "lesson_duration", id: "lesson_duration", required:true }, 
    { label: "FREE Lesson", className: "col-lg-4", inputType: "checkbox", name: "free_lecture_view", id: "free_lecture_view" , disable : freeCourse , checked : freeCourse  } 
  ];

  
  const onLessonCreationApiCall = () => {
    if(!lessonDetails?.lesson_title){
      showAlertMessage('Lesson Title field is required', 'fail');
      return;
    }
    if(!lessonDetails?.lesson_description){
      showAlertMessage('Lesson Description field is required', 'fail');
      return;
    }
    if(!lessonDetails?.lesson_link){
      showAlertMessage('Lesson Link field is required', 'fail');
      return;
    }
    if(!lessonDetails?.lesson_duration){
      showAlertMessage('Lesson Duration field is required', 'fail');
      return;
    }
    setSaveBtn(true);
    if (lesson_id){
      updateExistingCourseLesson();
    } else {
      createNewCourseLesson();
    }
  };

  const CourseLessonPopupModule = () => {
    return (
      <div className='popup_wrapper pb-1' style={{height:"max-content"}}>
        <div className="w-100 text-end">
          <button
            onClick={onLessonCreationApiCall}
            className="connect-button w-fit p-3"
            disabled={saveBtn}
          >
            <div>
              <span> Save </span>{" "}
              {saveBtn ? (
                <output
                  className="spinner-border spinner-border-sm ms-1"
                  aria-hidden="true"
                ></output>
              ) : (
                ""
              )}
            </div>
          </button>
        </div>
        <div style={{height:"100%",overflowY:"auto",overflowX:"hidden"}}>
        <FieldsRenderer
          fieldsArray={inputFieldsLessonsArray}
          updateState={setLessonDetails}
          currentState={lessonDetails}
        />
        </div>
      </div>
    )
  }

  return ( 
    <Modal status={openPopup} closeModal={() => setPopupState(false)}> 
      <div className='container'>
        {saveBtn ? <Loader /> : CourseLessonPopupModule()}
      </div>
    </Modal> 
  )
}

export default DetailViewLessonPopup;
import React, { useEffect, useState } from 'react';
import CustomDataGrid from '../../UI Helpers/DataGrid/CustomDataGrid';  
import CommonCalls from '../../CommonAPICalls/CommonCalls';

const UserMarksAttendance = () => {
    const [userExamAttendanceDetails, setUserExamAttendanceDetails] = useState([]);
    const [examDetails, setExamDetails] = useState([]);

    const [loading, setLoading] = useState(false);
 
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
 
    const apiCalls = new CommonCalls();

    useEffect(() => {
        const fetchData = async () => {
            await getUserExamDetails();
            await getUserExamAttendanceDetails();
        };
    
        fetchData();
    }, []);

    const getUserExamAttendanceDetails = async () => {
        setLoading(true);
        try {
            const apiResponse = await apiCalls.getExamAttendanceDetails();
            if (apiResponse) {
                setUserExamAttendanceDetails(apiResponse?.data);
                setTotalRecords(apiResponse?.data?.length);
                setLoading(false);
            }
        } catch (err) {
            setUserExamAttendanceDetails([]);
            setTotalRecords(0);
            setLoading(false);
        }
    }

    const getUserExamDetails = async () => { 
        try {
            const apiResponse = await apiCalls.getExamDetails();
            if (apiResponse) {
                setExamDetails(apiResponse?.data?.map((item) => {
                    return {
                        value: item?.exam_id,
                        label: `Topic: ${item?.exam_topic} - T.Days: (${item?.attendance_out_of}) - T.Marks: (${item?.marks_out_of})`
                    }
                })); 
            }
        } catch (err) {
            setExamDetails([]); 
        }
    };

    const gridColumnHeaders = [ 
        { field: 'exam_id', columnName: 'Exam Details', inputType: "dropdown", options: examDetails },
        { field: 'user_attendance', columnName: 'Your Attendance' },
        { field: 'user_marks', columnName: 'Your Marks' }
    ];

    return (
        <div className="main-cont">
            {" "}
            <h3 className="h1_main mt-2 mb-0">Marks & Attendance</h3> 
            <div className="common_admin_wrapper py-2 ">
                <CustomDataGrid 
                    columns={gridColumnHeaders} 
                    data={userExamAttendanceDetails} 
                    pageSize={pageSize} 
                    hideAllBtns={true}
                    checkboxRequired={false}
                    loadingState={loading}
                    pagination={false}   
                    totalRecords={totalRecords}
                    currentPage={pageNumber}
                    onPageChange={setPageNumber}
                    onPageSizeChange={setPageSize}
                />
            </div> 
        </div>
    )
};

export default UserMarksAttendance;
import React, { useState, useEffect } from "react";
import "./EnrolledPastPapers.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import Loader from "../../UI Helpers/Simple Loading/Loader";
import { useSelector } from "react-redux";
import { checkIfArrayIsEmpty } from "../../MasterComponents/CommonFunctionalities";

function EnrolledPastPapers({changeScreenFunc}) {
  const [pastPapers, setPastPapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const userLoginStatus = useSelector((state) => state.userLogin);

  const enrolledPastPapersIds = userLoginStatus.userInfo?.enrolled_past_papers;

  const apiCall = new CommonCalls();

  const getEnrolledCourses = async () => {
    if (checkIfArrayIsEmpty(enrolledPastPapersIds)) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const response = await apiCall.getEnrolledPastPapersById(
      enrolledPastPapersIds
    );
    if (response) {
      setError(false);
      setLoading(false);
      return response;
    } else {
      setLoading(false);
      setError("Could not load data from server");
      return;
    }
  };

  useEffect(() => {
    const getApiData = async () => {
      const enrolledCoursesResponse = await getEnrolledCourses();
      if (!error) {
        setPastPapers(enrolledCoursesResponse);
      }
    };

    getApiData();
  }, []);

  return (
    <div className="main-cont">
      {" "}
      <h3 className="h1_main mt-2 mb-0">Enrolled Past Papers</h3>
      {loading ? (
        <Loader />
      ) : (
        <div className="EnrolledPastPaperCard-container py-2 ">
          {
            pastPapers?
            <Swiper
            loop={true}
            spaceBetween={50}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 3,
              },
            }}
            className="card-list py-4 mx-0 w-100 px-2"
          >
            {pastPapers?.map((paper, ind) => (
              <SwiperSlide key={ind} style={{ height: "auto" }}>
                <CardBox
                  className="h-100"
                  cardImg={"./images/creatives/chemistryATPBanner.png"}
                  title={paper?.topical_paper_title}
                  content={paper?.topical_paper_description}
                  driveLink={paper?.topical_paper_drive_link}
                />
              </SwiperSlide>
            ))}
          </Swiper>
            :
            (
              <div className="d-flex flex-column align-items-center justify-content-center h-100 py-5">
                <h6 className="text-center">No Past Papers are currently enrolled. <span onClick={() => {
                    changeScreenFunc(6);
                  }} style={{cursor:'pointer',color:"#007DFF"}}>Click here</span> to explore and purchase Past Papers.</h6>
              </div>
            )
          }
          
        </div>
      )}
    </div>
  );
}

export default EnrolledPastPapers; 
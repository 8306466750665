import React from 'react';
import "./Story.css"

const Story = () => {
  return (
    <div className="container my-5 story-container">
      <div className="text-center mb-5">
        <h5 className="text-danger">My Story</h5>
        <h1 className='h1_main'>Moosa Khan’s Career Journey        </h1>
        <p className='para_main'>With several years of dedicated work experience, I've polished my teaching skills and perfected delivering lessons. Each day in the classroom is an opportunity to spark curiosity about chemistry among my students. Apart from my current affiliations, I have previously taught at various other renown institutes including The Alpha College, The Academy Defence View Campus and FGS. Additionally, I teach at multiple coaching centers in the evening.        </p>
      </div>
      <div className="container row flex-lg-row flex-column-reverse">
        <div className="col-lg-6 story-box">
          <div className="row">
            <div className='col-lg-6'>
              <div className="timeline-item">
                <h5 className="text-primary1">2023</h5>
                {/* <p className='para_mainn'>Launched course #300</p> */}
                <p className='para_main'>Joined Credo College as a visiting faculty A level Chemistry teacher Began teaching O level Chemistry at Taurus FGS </p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className="timeline-item">
                <h5 className="text-primary2">2022</h5>
                {/* <p className='para_mainn'>Launched course #300</p> */}
                <p className='para_main'>Became a part of Whales College as a visiting faculty A level Chemistry teacher Started teaching O level Chemistry at Wahaj Hussain’s School System</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className="timeline-item">
                <h5 className="text-primary3">2020</h5>
                {/* <p className='para_mainn'>Launched course #300</p> */}
                <p className='para_main'>Visiting faculty at Alpha College 2020 - 2021</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className="timeline-item">
                <h5 className="text-primary4">2015</h5>
                {/* <p className='para_mainn'>Launched course #300</p> */}
                <p className='para_main'>Project Engineer at National Refinery Limited 2015 - 2017</p>
              </div>
            </div>



          </div>
        </div>
        <div className="col-lg-6">
          <div className="text-center">
            <img src="/images/sir-moosa-khan.jpg" alt="Moosa Khan" className="img-fluid rounded story-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;

import React, { useEffect, useState } from 'react'
import Modal from '../../Model/Modal';
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import FieldsRenderer from '../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent';
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities';
import Loader from '../../UI Helpers/Simple Loading/Loader';

const DetailViewExamAttendancePopup = ({ setPopupState, examDetailsArray=[], openPopup, reloadParentGrid, examination_attendance_id=null, user_id=null }) => {
    const [saveBtn, setSaveBtn] = useState(false);
    const [examAttendanceDetails, setExamAttendanceDetails] = useState({});

    const apiCalls = new CommonCalls();

    useEffect(() => { getSystemExamAttendanceById() }, []);

    const getSystemExamAttendanceById = async () => {
        if(!examination_attendance_id) {
            setSaveBtn(false);
            return;
        };

        setSaveBtn(true);
        const examAttendanceResponse = await apiCalls.getUserExamAttendanceDetailsById(examination_attendance_id);
        if (examAttendanceResponse?.status === 200) {
            setExamAttendanceDetails(examAttendanceResponse?.data); 
            setSaveBtn(false);
        };
        setSaveBtn(false);
    };

    const createNewExamAttendance = async () => { 
        const examAttendanceResponse = await apiCalls.addNewUserExamAttendanceDetails({...examAttendanceDetails, user_id});
      
        if(examAttendanceResponse?.status == 201){
            showAlertMessage(examAttendanceResponse?.message, 'success');
            reloadParentGrid();
            setSaveBtn(false);
            setPopupState(false)
        } else {
            showAlertMessage('Student Exam Details couldnt be created!', 'fail');
            setSaveBtn(false);
        }
    };
  
    const updateExistingExamAttendance = async () => { 
        const updatelessonResponse = await apiCalls.updateUserExamAttendanceDetails(examination_attendance_id, examAttendanceDetails);
    
        if(updatelessonResponse?.status == 202){
            showAlertMessage(updatelessonResponse?.message, 'success');
            reloadParentGrid();
            setSaveBtn(false);
        } else {
            showAlertMessage('Student Exam Details couldnt be updated!', 'fail');
            setSaveBtn(false);
        }
    };

    const inputFieldsExamDetailsArray = [
        { label: "Exam", className: "col-lg-4", inputType: "dropdown", options: examDetailsArray, name: "exam_id", id: "exam_id", required:true },
        { label: "Student Attendance", className: "col-lg-4", inputType: "number", name: "user_attendance", id: "user_attendance", required:true },
        { label: "Student Marks", className: "col-lg-4", inputType: "number", name: "user_marks", id: "user_marks", required:true } 
    ];

    const onSaveBtnClick = () => {
        if(!examAttendanceDetails?.exam_id){
            showAlertMessage('Exam field is required', 'fail');
            return;
        }
        if(!examAttendanceDetails?.user_attendance){
            showAlertMessage('User Attendance field is required', 'fail');
            return;
        }
        if(!examAttendanceDetails?.user_marks){
            showAlertMessage('User Marks field is required', 'fail');
            return;
        }
        setSaveBtn(true);
        if (examination_attendance_id){
            updateExistingExamAttendance();
        } else {
            createNewExamAttendance();
        }
    };

    const ExamDetailsPopupModule = () => {
        return (
            <div className='popup_wrapper'>
                <div className="w-100 text-end">
                    <button
                        onClick={onSaveBtnClick}
                        className="connect-button w-fit p-3"
                        disabled={saveBtn}
                    >
                    <div>
                        <span> Save </span>{" "}
                        {saveBtn ? (
                            <output
                                className="spinner-border spinner-border-sm ms-1"
                                aria-hidden="true"
                            ></output>
                        ) : (
                        ""
                        )}
                    </div>
                    </button>
                </div>
                <FieldsRenderer
                    fieldsArray={inputFieldsExamDetailsArray}
                    updateState={setExamAttendanceDetails}
                    currentState={examAttendanceDetails}
                />
            </div>
        )
    }

    return (
        <Modal status={openPopup} closeModal={() => setPopupState(false)}> 
            <div className='container'>
                {saveBtn ? <Loader /> : ExamDetailsPopupModule()}
            </div>
        </Modal>
    )
}

export default DetailViewExamAttendancePopup;
import React, { useEffect, useState } from 'react'
import CommonCalls from '../../CommonAPICalls/CommonCalls'
import { useLocation, useNavigate } from 'react-router-dom'
import { validateForm } from '../../UI Helpers/HelperFunctions/Helper'
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities'
import Loader from '../../UI Helpers/Simple Loading/Loader'
import FieldsRenderer from '../../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent'
import Settings from '../../CommonAPICalls/Settings'


const apiCall = new CommonCalls();
 
const SystemTopicalPastPapersDetailView = () => {
  const [saveBtn , setSaveBtn] = useState(false)
  const [loading , setLoading] = useState(true)
  const [formInputData , setFormInputData] = useState({}) 
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paperId =  params.get('paperid');
  const navigate = useNavigate()

  const getPaperDetails = async ()=>{

      const response = await apiCall.getPaperById(paperId)
      if(response)
      {
        setFormInputData ({
          "topical_paper_year": response?.topical_paper_year,
          "topical_paper_title": response?.topical_paper_title,
          "past_paper_type_id": response?.past_paper_type_id,
          "topical_paper_description": response?.topical_paper_description,
          "topical_paper_price": response?.topical_paper_price,
          "free_view": response?.free_view,
          "topical_paper_drive_link": response?.topical_paper_drive_link,
          "enrolled_users": response?.enrolled_users
          })
        
          setLoading(false)
      }
  }
  useEffect(()=>{ 
      if(paperId)
      {
        setLoading(true)
        getPaperDetails()
      }
      else{
        setLoading(false)
      }
  },[paperId])
   
  const addPaperData = async()=>{
      setSaveBtn(true)
      let validationResponse = validateForm(inputsFieldAr,formInputData);
      if(!validationResponse)
        {
          setSaveBtn(false)
          return
        }
        let apiFunction =async()=>{ return await apiCall.addNewPaper(formInputData)}
        if(paperId)
        {
          apiFunction = async()=>{return await apiCall.updatePaper(paperId,formInputData)}
        }
      const response = await apiFunction();
      if(response?.status === 201 || 202)
      {
          setSaveBtn(false)
          if (response?.status === 201)
            {
              if(formInputData?.enrolled_users && response?.past_paper_id) {
                await apiCall.updateEnrollUsersToPastPaper(response?.past_paper_id, {
                  user_ids: formInputData?.enrolled_users
                }) 
              }
              navigate("/portal?tab_id=3")
            } 
            if(formInputData?.enrolled_users && paperId) {
              await apiCall.updateEnrollUsersToPastPaper(paperId, {
                user_ids: formInputData?.enrolled_users
              }) 
            }
          showAlertMessage(response?.message, "success")
          return response
      }
      else
      {
          setSaveBtn(false)
          showAlertMessage(response?.message, "fail")
          return 
      }
    }

    const inputsFieldAr = [
      {
        label: "Topical Paper Year",
        className: "col-lg-4",
        inputType: "text",
        name: "topical_paper_year",
        id: "topical_paper_year",
        required:true
      },
      {
        label: "Topical Paper Title",
        className: "col-lg-4",
        inputType: "text",
        name: "topical_paper_title",
        id: "topical_paper_title",
        required:true
      },
      {
        label: "Past Paper Type Id",
        className: "col-lg-4",
        inputType: "dropdown",
        options:Settings.enumTypes,
        name: "past_paper_type_id",
        id: "past_paper_type_id",
        maxValue:3,
        required:true
      },
      {
        label: "Topical Paper Description",
        className: "col-lg-4",
        inputType: "text",
        name: "topical_paper_description",
        id: "topical_paper_description",
        required:true
      },
      {
        label: "Topical Paper Price",
        className: "col-lg-4",
        inputType: "number",
        name: "topical_paper_price",
        id: "topical_paper_price"
      },
      {
        label: "FREE Past Paper",
        className: "col-lg-4",
        inputType: "checkbox",
        name: "free_view",
        id: "free_view"
      },
      {
        label: "Topical Paper Drive Link",
        className: "col-lg-4",
        inputType: "text",
        name: "topical_paper_drive_link",
        id: "topical_paper_drive_link",
      },
      {
        label: "Users (For Granting Course Access)",
        className: "col-lg-4",
        inputType: "multi_dropdown", 
        defaultValues: formInputData?.enrolled_users ?? [],
        name: "enrolled_users", 
        id: "enrolled_users"
      }
    ]
  
  return (
    <>
    {
      loading?
      <div className='d-flex align-items-center justify-content-center' style={{height:"80vh"}}>
        <Loader/>
      </div>
      :
      <div className='past-paper-detail-view-container container-fluid py-4'>
      <form
            onSubmit={(e)=>{
              e.preventDefault()
              setSaveBtn(true)
              addPaperData();
            }}
          >
            <div className='w-100 text-end '>
                <button type="submit" className="connect-button w-fit p-3" disabled={saveBtn}>
                <div><span> Save </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>
                </button>
                </div>
             <FieldsRenderer
                fieldsArray={inputsFieldAr}
                updateState={setFormInputData}
                currentState={formInputData}
              />
            
           
              
            
          </form>
    </div>
    }
  </>
  )
}

export default SystemTopicalPastPapersDetailView;

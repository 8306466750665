import React from 'react';
import "./aboutUs.css"; 
import { useNavigate } from 'react-router-dom';
import TextCounter from '../TextCounter/TextCounter';

const AboutUs = ({
  btn=false
}) => {
  const navigate = useNavigate()
  return (
    <div className="container about-container">
      <div className="row">
        <div className="col-lg-6 left-section">
          <h2 className='h2_main'>Moosa Khan</h2>
          <h1 className='h1_main'>Vision and Mission <br />of Moosa Khan </h1>
          <p className='upper-paragraph para_main'>Welcome to our O level 5070, IGCSE 0620 and A level 9701 chemistry corner, dedicated to promoting curiosity and knowledge. Explore our comprehensive resources, designed to support both students and teachers on their learning journey. Join us as we reveal the applications and significance of chemistry, making it intriguing and accessible for everyone.          </p>
          
          <div className="d-flex flex-container d-none d-lg-flex">
            <div className="image-container">
              <img src="/images/vision.svg" alt="Logo 1" className='img1'  />
              
            </div>
            <div className="text-container">
              <div>
                <h3 className='h3_main '>Vision</h3>
                <p className='para_main'>To create a passion for chemistry in the generations to come, encouraging interest and critical thinking through interactive learning sessions.</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-container d-none d-lg-flex">
            <div className="image-container">
            
              <img src="/images/mission.svg" alt="Logo 1" className='img2' />
            </div>
            <div className="text-container">
             
              <div>
                <h3 className='h3_main'>Mission</h3>
                <p className='para_main'>I am committed to providing exceptional educational resources for students. My goal is to compile a comprehensive collection consisting of notes and past papers designed to assist students excel in their studies.  </p>
              </div>
              
            </div>
          </div>
          {btn &&
          <button className='connect-button' onClick={()=>{navigate("/courses")}}>Explore Courses</button>
          }
        </div>
        <div className="col-lg-6 right-section">
          <img src="/images/about-us-banner.png" alt="Large Image" className='large-img'/> 
          <div className="counter-section d-flex align-items-center justify-content-center gap-4 py-4">
            <TextCounter
              end={170}
              repeatScroll={false}
              speed={50}
              PostText='K+'
              title={"Youtube Views"}
              startOnView={true}
            />
            <TextCounter
              end={5000}
              repeatScroll={false}
              speed={0.1}
              PostText='+'
              title={"Active Students"}
            />
          </div> 
        </div>
      </div>
      
          
      <div className="d-flex flex-container d-lg-none">
            <div className="image-container">
              <img src="/images/vision.svg" alt="Logo 1" className='img1'  />
              
            </div>
            <div className="text-container">
              <div>
                <h3 className='h3_main '>Vision</h3>
                <p className='para_main'>To create a passion for chemistry in the generations to come, encouraging interest and critical thinking through interactive learning sessions.</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-container d-lg-none">
            <div className="image-container">
            
              <img src="/images/mission.svg" alt="Logo 1" className='img2' />
            </div>
            <div className="text-container">
             
              <div>
                <h3 className='h3_main'>Mission</h3>
                <p className='para_main'>I am committed to providing exceptional educational resources for students. My goal is to compile a comprehensive collection consisting of notes and past papers designed to assist students excel in their studies.  </p>
              </div>
            </div>
          </div>
          
    </div>
  );
};

export default AboutUs;

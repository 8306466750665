import React from "react";
import styles from "./HomeHero.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Sponsor from "../Sponsor/Sponsor";
import Navbar from "../Header/Navbar";


const HomeHero = () => {
  return (
    <div 
     style={{position:"relative"}}
    >
      <video className={styles.backgroundVideo} autoPlay loop muted>
        <source src="/homebg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.Hero} id="hero">
 
  <div className={styles.Hero_Content}>
  <h1 className="heading_capital mt-5">
  <h2 className={styles.homeherosmallheading}>Sir moosa khan</h2>
  <span className="d-flex flex-column align-items-center justify-content-center gap-2 ">
    <span className={styles.homeheromainheading}>
    Think like a <span className={styles.redtext}>proton</span>, 
    </span>
    <span className={styles.homeheromainheading}>
      always positive!
    </span>
  </span>
  
</h1>

    <p className={[styles.homeheropara]}>Explore the world of chemistry with Sir Moosa Khan, where we discover the positivity of protons and the fascinating subject of chemistry. Just like protons, we stay charged with enthusiasm. Join us on a journey of learning about the elements and reactions that light up our daily life.    </p>
  </div>
  
  <div className="d-lg-block d-none py-3">
  <Sponsor/>
  
    </div>
  
  
</div>
<div className="d-lg-none d-block py-3" >
  <Sponsor/>
  
    </div>

    </div>
  );
};

export default HomeHero;

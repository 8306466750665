import React, { useState,useEffect } from "react";
import "./SideSlider.css";
import { useNavigate } from "react-router-dom";



function SideSlider({ changeScreenTab,selctedTabNumber, setToggleSidebar,sideOptions }) {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(selctedTabNumber);
  useEffect(() => {
    setActiveTab(selctedTabNumber);
  }, [selctedTabNumber]);
  return (
    <div className="SideSlider-container">
      <div className="container">
        <div className="d-flex align-items-center flex-column gap-4">
          {sideOptions.map((option, ind) => {
            return (
              <div
                className={`card `}
                onClick={() => {
                  navigate('/portal',{
                    state: { activeTab: ind}});
                  setActiveTab(ind);
                  changeScreenTab(ind);
                  setToggleSidebar(window.innerWidth < 992 ? true : false);
                }}
              >
                <div className="d-flex align-items-center gap-4">
                  <img src={option.image} alt="title icon" className="tab-icon" />
                  <p
                    className={`mb-0 tab-title ${
                      activeTab == ind && "active-tab-title"
                    }`}
                  >
                    {option.title}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SideSlider;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../UI Helpers/DataGrid/CustomDataGrid";
import CommonCalls from "../../CommonAPICalls/CommonCalls";
import { gridLinkValueFormatter } from "../../MasterComponents/CommonFunctionalities"; 
import { showAlertMessage, checkIfArrayIsEmpty } from '../../MasterComponents/CommonFunctionalities';
import Settings from "../../CommonAPICalls/Settings";
 
const SystemCoursesListings = () => {
    const [coursesData, setCoursesData] = useState([]);

    const [loading, setLoading] = useState(true);
  
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
  
    const apiCalls = new CommonCalls();
  
    const navigate = useNavigate();

    useEffect(() => { getAllSystemCourses(); }, []);
  
    const getAllSystemCourses = async () => { 
        try {
            const apiResponse = await apiCalls.getSystemCourses();
            if (apiResponse) {
                setCoursesData(apiResponse?.data);
                setTotalRecords(apiResponse?.data?.length);
                setLoading(false);
            }
        } catch (err) {
            setCoursesData([]);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
  
    const onCourseNameClicked = (row, colKey) => {
        if(colKey === 'course_name'){
            navigate(`/portal/system-courses?courseid=${row?.course_id}`);
        }  
    };
  
    const onDeleteSystemCourses = async (rowItems) => {
        if(checkIfArrayIsEmpty(rowItems)) {
            showAlertMessage('Please select any rows to delete!', 'fail');
            return;
        };
  
        setLoading(true);
    
        for (const item of rowItems) {   
            try {
                const apiResponse = await apiCalls.removeSystemCourses(item?.course_id);
                if(apiResponse) {
                    showAlertMessage(apiResponse?.message, 'success');
                }
            } catch (error) {
                showAlertMessage(error, 'fail');
            }
        };
    
        getAllSystemCourses();
    };

    const gridColumnHeaders = [
        { field: 'course_name', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Course Name' },
        { field: 'course_description', columnName: 'Course Description' },
        { field: 'course_type_id', columnName: 'Course Type', inputType: "dropdown", options: Settings.enumTypes },
        { field: 'course_level', columnName: 'Course Level', inputType: "dropdown", options: Settings.enumCourseLevels },
        { field: 'slug_url', columnName: 'Slug URL' }, 
        { field: 'course_price', columnName: 'Course Price' }  
    ];

    return (
        <div className='listings_view_wrapper'> 
            <h1 className="h1_main">All System Courses</h1>
    
            <CustomDataGrid 
                columns={gridColumnHeaders} 
                data={coursesData} 
                pageSize={pageSize} 
                loadingState={loading}
                pagination={false}
                onAddClick={() => navigate('/portal/system-courses')}
                onCellClicked={onCourseNameClicked}
                onSelectedRowsDelete={onDeleteSystemCourses}
                totalRecords={totalRecords}
                currentPage={pageNumber}
                onPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
            />
        </div>
    )
}

export default SystemCoursesListings;
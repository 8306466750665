import React, { useEffect, useRef } from "react";
import "./Modal.css";   

export default function Modal(props) {
  const modalRef = useRef(null);

  function freezeScreen() {
    if (window.innerWidth >= 768) {
        document.body.style.position = 'fixed';
        document.body.style.width = "100%"
    }
}

function unfreezeScreen() {
        document.body.style.position = 'static';
}

  useEffect(() => {
    if (props.status) {
      document.body.classList.add('active-modal');
      modalRef.current.style.opacity = "1";
      modalRef.current.style.visibility = "visible";
    
    } else {
      document.body.classList.remove('active-modal');
      modalRef.current.style.opacity = "0";
      modalRef.current.style.visibility = "hidden";
      
    }
  }, [props.status]);

  return (
    <div className={`modal-container ${props.status ? 'show' : ''}`} ref={modalRef}>
      <div className="overlay" onClick={()=>{if(props.closeModal !== undefined){props.closeModal()}}}></div>
      {
        props.closeModal &&
        <div className="close-modal" onClick={()=>{props.closeModal()}}>
          <img src="/icons/login-cross-icon.png" alt="cross-icon" className="login-cross-icon d-none d-lg-flex img-fluid" />
        </div>
      }
      <div className="modal-main">
        
        <div className="modal-content"> 
          {props.children}
        </div>
      </div>
    </div>
  );
}

import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer
} from "./reducers/userReducers";
import cartItemsReducer from "./reducers/cartItemsReducer";
import { loadState } from "./reducers/cartItemsReducer";

const reducer = combineReducers({
  userLogin: userLoginReducer ,
  cartItems : cartItemsReducer
});

const initialState = {
  userLogin: { userInfo: null },
  cartItems:loadState()
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
import { fetchUserDetails } from "../../store/actions/userActions";
import { showAlertMessage } from "../MasterComponents/CommonFunctionalities";
import Settings from "./Settings";
import SystemApiCallSettings from "./SystemApiCallSettings";  

export default class CommonCalls {

    /* GET API Calls */
    async getSystemUsers(page_number=1, page_size=10, search_key=null) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
            {
                fetchUserDetails()
            }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl+`/all_users?page_number=${page_number}&page_size=${page_size}${search_key?`&search=${search_key}`:''}`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getSystemNotes() {
        try { 
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalNotesUrl+`/get_all_topical_notes`);
            if (res.status === 200) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getSystemOnlineClasses() {
        try { 
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiOnlineClassesUrl+`/get_all_online_classes_details`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getSystemTopicalPastPapers() {
        try { 
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalPastPapersUrl+`/get_all_topical_past_papers`, { token: userInfoToken });
            if (res.status === 200) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getSystemCourseLessons(lesson_id) {
        try {
            const res = await SystemApiCallSettings('GET', `${Settings.apiCourseUrl}/get_lessons_by_course/${lesson_id}`);
            if (res?.status === 200) {
                return res; 
            } else { 
                return { data: [] };  
            }
        } catch (error) { 
            return { data: [] };  
        }
    }

    async getSystemCourses() {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl+'/get_all_courses');
            if (res.status === 200) {
                return res;
            } else { 
                return {}; 
            }
        } catch (error) { 
            return {};  
        }
    };

    async getSystemCoursesByType(courseType,enrolledCourse=[]) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl+`/get_courses_by_type/${courseType}`,{}, true,{},'application/json',true);
            if (res.status === 200) {
                if(enrolledCourse)
                {
                    return res.data.filter(course => !enrolledCourse.includes(course.course_id))
                }
                else
                {
                    return res.data;
                }
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    
    async getCourseById(courseId) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl+`/get_course/${courseId}`, {token:userInfoToken}, true);
            if (res?.status === 200) {
                return res.data;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            showAlertMessage("could not load course","fail")
            return [];  
        }
    };

    async addNewCourse(courseDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiCourseUrl+'/add_new_course', {token:userInfoToken}, true,courseDetails);
           if (res?.status === 201) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async updateEnrollUsersToCourse(courseId, userEnrollDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiCourseUrl+`/enroll_user_to_course/${courseId}`, {token:userInfo?.token}, true,userEnrollDetails);
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async updateEnrollUsersToPastPaper(pastPaperId, userEnrollDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiTopicalPastPapersUrl+`/enroll_user_to_past_paper/${pastPaperId}`, {token:userInfo?.token}, true,userEnrollDetails);
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async updateCourse(courseId , courseDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiCourseUrl+`/update_course/${courseId}`, {token:userInfo?.token}, true,courseDetails);
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getTropicalPastPapersByType(courseType) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalPastPapersUrl+`/get_past_paper_by_type/${courseType}`);
            if (res.status === 200) {
                return res.data;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    
    async getEnrolledPastPapersById(paperIds) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiTopicalPastPapersUrl+'/get_past_papers_by_ids', {token:userInfoToken}, true,{ "past_papers_ids" :paperIds});
            if (res?.status === 200) {
                return res.data;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getEnrolledCourseById(courseIds) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiCourseUrl+'/get_courses_by_ids', {token:userInfoToken}, true,{ "course_ids" :courseIds});
            if (res?.status === 200) {
                return res.data;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getCourseBySlug(courseSlug) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl+`/get_course_by_slug/${courseSlug}`, );
            if (res.status === 200) {
                return res.data;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    async getLectureByCourseId(courseId) {
        try {
            
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl+`/get_lessons_by_course/${courseId}`);
            if (res.status === 200) {
                return res.data;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getOnlineClasses() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiOnlineClassesUrl+`/get_all_online_classes_details`,{token:userInfoToken});
            if (res?.status === 200) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getExamDetails() {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiExamsUrl+`/get_all_exam_details`,{token:userInfoToken});
            if (res?.status === 200) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async sendQuestionForm(formData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiContactUrl+'/user_questions', {}, true,formData);
            if (res?.status === 201) {
                return res;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async sendLeadQuestionForm(formData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiContactUrl+'/user_lead_questions', {}, true,formData);
            if (res?.status === 201) {
                return res;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async paymentform(formData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiContactUrl+'/payment_proceed', {}, true,formData);
            
            if (res?.status === 201) {
                return res;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };


    // Exam api
    async getExamById(examId) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiExamsUrl+`/get_exam_detail/${examId}`, {token:userInfoToken}, true);
            if (res?.status === 200) {
                return res.data;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            showAlertMessage("could not load Exam","fail")
            return [];  
        }
    };
    async addNewExam(examDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiExamsUrl+'/add_new_exam_detail', {token:userInfoToken}, true,examDetails);
           if (res?.status === 201) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    async updateExam(examId , examDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiExamsUrl+`/update_exam_detail/${examId}`, {token:userInfo?.token}, true,examDetails);
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    // Notes api
    async getNotesById(notesid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalNotesUrl+`/get_topical_notes/${notesid}`, {token:userInfoToken}, true);
            if (res?.status === 200) {
                return res.data;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            showAlertMessage("could not load Notes","fail")
            return [];  
        }
    };
    async addNewNotes(notesDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiTopicalNotesUrl+'/add_new_topical_notes', {token:userInfoToken}, true,notesDetails);
           
           if (res?.status === 201) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    async updateNotes(notesid , notesDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiTopicalNotesUrl+`/update_topical_note/${notesid}`, {token:userInfo?.token}, true,notesDetails);
            
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    // Online Class api
    async getClassById(classid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiOnlineClassesUrl+`/get_online_class_detail/${classid}`, {token:userInfoToken}, true);
            if (res?.status === 200) {
                return res.data;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            showAlertMessage("could not load Online Class","fail")
            return [];  
        }
    };
    async addNewClass(classDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiOnlineClassesUrl+'/add_new_online_class_detail', {token:userInfoToken}, true,classDetails);
           
           if (res?.status === 201) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    async updateClass(classid , classDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiOnlineClassesUrl+`/update_online_class_detail/${classid}`, {token:userInfo?.token}, true,classDetails);
            
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    // Topical Past Paper api /get_past_paper
    async getPaperById(paperid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalPastPapersUrl+`/get_past_paper/${paperid}`, {token:userInfoToken}, true);
            if (res?.status === 200) {
                return res.data;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            showAlertMessage("could not load past paper","fail")
            return [];  
        }
    };
    async addNewPaper(paperDetails) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiTopicalPastPapersUrl+'/add_new_past_paper', {token:userInfoToken}, true,paperDetails);
           
            if (res?.status === 201) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    async updatePaper(paperid , paperDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiTopicalPastPapersUrl+`/update_past_paper/${paperid}`, {token:userInfo?.token}, true,paperDetails);
            
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    // User Controls Admin
    async getUserById(userid) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl+`/${userid}`, {token:userInfoToken?.token,user_type_id : userInfoToken?.user_id}, true);
            
            if (res?.status === 200) {
                return res.data;
            } else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            showAlertMessage("could not load past paper","fail")
            return [];  
        }
    };
    async updateUser(userid , userDetails) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl+`/update_user_profile/${userid}`, {token:userInfo?.token}, true,userDetails);
            
            if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getExamAttendanceDetails(userId=null) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfoToken?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl+`/get_user_exam_details/${!userId?userInfoToken?.user_id:userId}`, {token: userInfoToken?.token});
            if (res?.status === 200) {
                return res;
            } else { 
                return {}; 
            }
        } catch (error) { 
            return {};  
        }
    }; 

    async getUserExamAttendanceDetailsById(examination_attendance_id) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl+`/get_user_exam/${examination_attendance_id}`, {token: userInfoToken}, true);
            if (res?.status === 200) {
                return res;
            } else {  
                return {}; 
            }
        } catch (error) {  
            return {};  
        }
    };
 
    async addNewUserExamAttendanceDetails(payload) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl+`/add_new_user_exam_details`, {token: userInfoToken}, false, payload);
            if (res?.status === 201) {
                return res;
            } else { 
                return {}; 
            }
        } catch (error) { 
            return {};  
        }
    };
 
    async updateUserExamAttendanceDetails(examination_attendance_id, apiPayload) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl+`/update_user_exam_details/${examination_attendance_id}`, {token: userInfo?.token}, false, apiPayload);
            
            if (res?.status === 202) {
                return res;
            } else { 
                return {}; 
            }
        } catch (error) { 
            return {};  
        }
    };
    // User api
    async registerUserToDB(userData) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiUserUrl+'/register-new-user', {}, true,userData);
            if (res.status === 201) {
                return res;
            } else if(res?.status === 429) { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
            else { 
                showAlertMessage(res?.message,"fail")
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };
    async updateUserProfile(userData) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiUserUrl+`/update_user_profile/${userInfo?.user_id}`, {token:userInfo?.token}, true,userData);
           if (res?.status === 202) {
                return res;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getUserTokenStatus() {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl+`/${userInfo?.user_id}`, {token:userInfo?.token}, true,{},'application/json',true);
           if (res?.status === 200) {
                return true;
            } else { 
                return false; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getUserDetails() {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            const res = await SystemApiCallSettings('GET', Settings.apiUserUrl+`/${userInfo?.user_id}`, {token:userInfo?.token,user_type_id : userInfo?.user_id}, true,{},'application/json',true);
           if (res?.status === 200) {
                return {response:res,token:userInfo?.token};
            }
            else if(res?.status == 404)
            {
                showAlertMessage(res?.message , "fail")
                fetchUserDetails()
            } 
            else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    async getNotesByType(courseType) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiTopicalNotesUrl+`/get_topical_notes_by_type/${courseType}`);
            if (res.status === 200) {
                return res.data;
            } else { 
                return []; 
            }
        } catch (error) { 
            return [];  
        }
    };

    /* Api Calls for Course Lessons */
          
    async getCourseLessonById(lesson_id) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl+`/get_lesson_by_id/${lesson_id}`, {token:userInfoToken}, true);
            if (res?.status === 200) {
                return res;
            } else {  
                return {}; 
            }
        } catch (error) {  
            return {};  
        }
    };

    async addNewCourseLesson(payload) {
        try {
            const userInfoToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
            if(!userInfoToken)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('POST', Settings.apiCourseUrl+`/add_new_course_lesson`, {token: userInfoToken}, false, payload);
            if (res?.status === 201) {
                return res;
            } else { 
                return {}; 
            }
        } catch (error) { 
            return {};  
        }
    };

    async updateCourseLesson(lesson_id, apiPayload) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('PUT', Settings.apiCourseUrl+`/update_course_lesson/${lesson_id}`, {token: userInfo?.token}, false, apiPayload);
            
            if (res?.status === 202) {
                return res;
            } else { 
                return {}; 
            }
        } catch (error) { 
            return {};  
        }
    };
 
    /* GET By ID Calls */ 
    async getDocumentItemsById(userProps, document_id) {
        try {
            const res = await SystemApiCallSettings('GET', Settings.apiCourseUrl+`/get_document_by_id/${document_id}`, userProps, false);
            if (res.status === 200) {
                return res.data;
            } else { 
                return {}; 
            }
        } catch (error) { 
            return {};  
        }
    };

    /* PUT API Calls */
    async updateDocumentById(userProps, document_id, payload) {
        try {
            const res = await SystemApiCallSettings('PUT', Settings.apiCourseUrl+`/sample_update_api/${document_id}`, userProps, false, payload);
            return res;
        } catch (error) { 
            return error;  
        }
    };

    /* POST API Calls */
    async addNewDocument(userProps, payload) {
        try {
            const res = await SystemApiCallSettings('POST', Settings.apiCourseUrl+`/sample_post_api`, userProps, false, payload);
            return res;
        } catch (error) { 
            return error;  
        }
    };

    /* Delete API Calls */
    async removeSystemNote(note_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiTopicalNotesUrl +'/delete_topical_notes/'+note_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };

    async removeSystemOnlineClass(class_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiOnlineClassesUrl +'/delete_online_classes_details/'+class_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };

    async removeSystemTopicalPastPaper(past_paper_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiTopicalPastPapersUrl +'/delete_past_papers/'+past_paper_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };

    async removeSystemExams(exam_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiExamsUrl +'/delete_exam_details/'+exam_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };
  
    async removeSystemCourses(course_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiCourseUrl +'/delete_courses/'+course_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };

    async removeSystemCourseLessons(lesson_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiCourseUrl +'/delete_course_lessons/'+lesson_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };

    async removeSystemExamAttendance(examination_attendance_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiUserUrl +'/delete_user_exam_details/'+examination_attendance_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };

    async removeSystemUser(user_id) {
        try {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(!userInfo?.token)
                {
                    fetchUserDetails()
                }
            const res = await SystemApiCallSettings('DELETE', Settings.apiUserUrl +'/delete_user_profile/'+user_id, {token: userInfo?.token});
            return res;
        } catch (error) { 
            return {};
        }
    };
};
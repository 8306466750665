import React from 'react'
import LatestNews from '../../components/LatestNews/LatestNews'
import NewsAndUpdates from '../../components/News&Updates/NewsAndUpdates'
import CardGrid from '../../components/Cardgrid/CardGrid'
import TestimonialForm from '../../components/TestimonialForm/TestimonialForm';
import PlainHero from '../../components/PlainHero/PlainHero';

const NewsAndUpdatesPage = () => {
  return (
    <div>
       <PlainHero
        heading="Our News & Update"
        content="Keep up to date with the latest trends, tips, and industry news in barbering and grooming. From product launches to expert advice, we've got you covered."
       />
        {/* <NewsAndUpdates /> */}
        <div style={{backgroundImage:"url('/images/creatives/home-composite-bg.png')",backgroundRepeat:'no-repeat',backgroundSize:"contain"}}>
          <LatestNews />
          <CardGrid />
          <TestimonialForm/>
        </div>
    </div>
  )
}

export default NewsAndUpdatesPage
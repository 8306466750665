import React, { useState, useEffect, useRef } from 'react';
import "./CommonComponentsStylings.css";
import CommonCalls from '../../CommonAPICalls/CommonCalls';
import Settings from "../../CommonAPICalls/Settings";

const MultiSelect = ({ defaultValues, onChange, name, Label }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [userOptions, setUserOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const dropdownRef = useRef(null);

  const pageSize = 50;

  const apiCall = new CommonCalls();

  useEffect(() => {
    setSelectedValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (isDropdownOpen && pageNumber === 1) {
      getUserOptions(pageNumber, pageSize);
    }
  }, [isDropdownOpen]);

  const getUserOptions = async (pageNumber, pageSize) => {
    setIsFetching(true); 
    const response = await apiCall.getSystemUsers(pageNumber, pageSize);
    if (response) {
      const users = response?.data?.map(item => ({
        value: item?.user_id,
        label: `${item?.full_name} - ${(Settings.enumTypes?.find(val => val.value == item?.student_category))?.label || 'No Student Category'}`
      }));
      setUserOptions(prevOptions => [...prevOptions, ...users]);
    }
    setIsFetching(false);
  };

  const handleSelectChange = (value) => {
    let updatedValues;
    if (selectedValues.includes(value)) {
      updatedValues = selectedValues.filter(v => v !== value);
    } else {
      updatedValues = [...selectedValues, value];
    }
    setSelectedValues(updatedValues);
    onChange(updatedValues, name);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isFetching) { 
      setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (pageNumber > 1) { 
      getUserOptions(pageNumber, pageSize);
    }
  }, [pageNumber]);

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-end py-2 multi-select-main'>
      <div className='w-100 multi-select-inp d-flex align-items-center justify-content-between px-2' onClick={toggleDropdown}>
        <p>{Label}</p>
        <img src='/images/icons/dropdown-icon.svg' alt="arrow icon"/>
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu" ref={dropdownRef} onScroll={handleScroll}>
          {userOptions?.map(option => (
            <div key={option.value} className="dropdown-item">
              <input
                type="checkbox"
                checked={selectedValues.includes(option.value)}
                onChange={() => handleSelectChange(option.value)}
              />
              <p className='mb-0'>{option.label}</p>
            </div>
          ))}
          {isFetching && <p className='ps-3 pe-3 mb-0'>Loading more users...</p>}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
